.modern-radio-container {
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-outer-circle {
  /* width: 28.21px;
  height: 28.21px; */
  min-width: 1.5vw;
  min-height: 1.5vw;
  border: 1.69238px solid #838383 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
}

.radio-inner-circle {
  width: 0.85vw;
  height: 0.85vw;
  border-radius: 50%;
  background-color: #f1b843;
  border: 1px solid #bea46e;
  transition: all 0.1s linear;
}
.unselected {
  border: 1.69238px solid #838383;
}
.unselected-circle {
  width: 0px;
  height: 0px;
  background-color: white ;
  border: 1px solid white ;
}

.helper-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 42px;
  /* identical to box height */
  min-width: 25px;
  color: #464646;
}
