.lightBlue {
    background-color: rgba(2, 119, 173, 0.1);
}

.light {
    border-radius: 0.313rem;
    border: 0.063rem solid rgba(230, 230, 230, 1);
    box-shadow: 0 0.25rem 1.875rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
}






