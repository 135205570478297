@media only screen and (max-width: 480px) {
  .guest-login-page-container {
    width: 100% !important;
    overflow-x: hidden;
    height: 100vh !important;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem 0 2rem 0 !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 950px) {
  .guest-login-page-container {
    width: 75% !important;
    overflow-x: hidden;
    height: 75% !important;
    overflow-y: auto;
    position: relative;
    background: #FCFCFC;
    box-shadow: 0px 2.19469px 3.29204px rgba(0, 0, 0, 0.25);
    border-radius: 10.9735px;
    padding: 2rem 0 2rem 0 !important;
  }
}

@media only screen and (min-width: 951px) {
  .guest-login-page-container {
    width: 100% !important;
    height: 100% !important;
    position: relative;
    background: #FCFCFC;
    padding: 0 0 2rem 0 !important;
  }
}

@media only screen and (max-width: 480px) {
  .left-login-wrapper {
    height: 100% !important;
    width: 100% !important;
    background-size: cover;
    background-position: center;
    flex-wrap: wrap;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-image: url("./images/rainbow.svg") !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 950px) {
  .left-login-wrapper {
    width: 100% !important;
    height: 100% !important;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 951px) {
  .left-login-wrapper {
    width: 38% !important;
    height: 100% !important;
    background: #FCFCFC;
    padding: 3%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
}

.right-img-wrapper {
  width: 62% !important;
  padding: 0px;
}

.bg-img-container {
  height: 100vh;
  /* width: 100% !important; */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 0%;
  margin: 0%;
}

img {
  border-style: none;
}

.guest-login-background-image {
  background-image: url("./images/rainbow.svg");
  /* position: fixed; */
  text-align: center;
  background-size: cover;
  background-position: center;
  overflow: -moz-scrollbars-vertical;
  overflow-y: auto;
  width: 100% !important;
  height: 100% !important;
}

.guest-login-logo {
  background-size: cover;
  height: 75px;
  width: 75px;
}

@media only screen and (max-width: 480px) {
  .guest-login-title {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #0277AD;
    padding: 0%;
    text-align: center;
    text-align-last: center;
  }
}

@media only screen and (min-width: 480px) and (max-width: 950px) {
  .guest-login-title {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    color: #0277AD;
    padding: 0%;
    text-align: center;
    text-align-last: center;
  }
}

@media only screen and (min-width: 951px) {
  .guest-login-title {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    color: #0277AD;
    padding: 0%;
    text-align: center;
    text-align-last: center;
  }
}

.guest-login-subtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #1D1D1D;
  text-align: justify;
  text-align-last: center;
}

.guest-login-input-title {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 16;
  text-align: left;
  padding: 2% 3% 2% 3%;
}

.guest-login-name-input {
  padding: 0% 2% 0% 2% !important;
  margin: 0%;
  width: 98%;
  height: 50px;
  margin: 0px;
  border-radius: 3.144px;
  color: #000000;
  font-weight: 600;
  box-sizing: border-box;
  border: solid #939393;
  border-width: 1px;
}

.space {
  margin-top: clamp(10%, 10%, 10px);
  margin-bottom: clamp(10%, 10%, 10px);
  margin-left: 0%;
  margin-right: 0%;
}

.err {
  width: 90%;
  height: 10px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #C93E3E;
  margin-top: 2%;
  margin-bottom: min(5%, 30px);
}

.guest-login-poll-input {
  padding: 0px !important;
  width: 80%;
  height: 55px;
  margin: 0px 2% 0px 2%;
  border-radius: 3.144px;
  color: #000000;
  font-weight: 600;
  box-sizing: border-box;
  text-align: center;
  border: solid #939393;
  border-width: 1px;
}

.name-box {
  margin: 0%;
  padding: 1%;
}

.name-row {
  padding: 0% 2% 0% 2%;
}

.name-cont {
  margin: 5% 0 0 0;
  padding: 0%;
  width: 100%;
  align-items: center;
  align-self: center;
}

.poll-box {
  margin: 0%;
  padding: 1%;
}

.poll-row {
  padding: 0% 2% 0% 2%;
}

.poll-cont {
  margin: 5% 0 0 0;
  padding: 0%;
  width: 100%;
  align-items: center;
  align-self: center;
}

.guest-login-join-button {
  padding: 0px !important;
  width: 100%;
  height: 50px;
  border: none;
  padding: 12.576px 20.96px;
  background: #0277AD;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 4.36474px;
  color: #FFFFFF;
  font-weight: 400;
  /* margin-bottom: 5%; */
}

.guest-login-join-button:hover {
  border: 2.02px solid #0277AD;
  background: #FFFFFF;
  color: #0277AD;
}

@media only screen and (max-width: 480px) {
  .or-line {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #252525;
    margin: 10% 0% 10% 0%;
  }

  .or-line:before {
    margin-right: 10px;
    content: "";
    display: inline-block;
    width: calc(50vw - 50px);
    height: 1px;
    background: #252525;
  }

  .or-line:after {
    margin-left: 10px;
    content: "";
    display: inline-block;
    width: calc(50vw - 50px);
    height: 1px;
    background: #252525;
  }
}

@media only screen and (min-width: 481px) {
  .or-line {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #252525;
    margin: 2.5% 0% 2.5% 0%;
  }
}

.guest-login-QR-button {
  padding: 0px !important;
  width: 100%;
  height: 50px;
  border: none;
  padding: 12.576px 20.96px;
  background: #FFFFFF;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border: 2.02px solid #0277AD;
  border-radius: 4.36474px;
  color: #0277AD;
  font-weight: 400;
}

.guest-login-QR-button:hover {
  background: #0277AD;
  color: #FFFFFF;
}

@media only screen and (max-width: 480px) {
  .guest-login-bottom-tether {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 950px) {
  .guest-login-bottom-tether {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 12.5%;
  }
}

@media only screen and (min-width: 951px) {
  .guest-login-bottom-tether {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0;
  }
}

.guest-login-issues {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
  padding: 0px;
  margin: 15px 0 15px 0;
}

.guest-login-footer {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #464646;
  margin: 0px;
  padding: 0px;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike>span {
  position: relative;
  display: inline-block;
}

.strike>span:before,
.strike>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 125px;
  height: 1px;
  background: #000;
}

.strike>span:before {
  right: 100%;
  margin-right: 15px;
}

.strike>span:after {
  left: 100%;
  margin-left: 15px;
}

.div-wrapper{
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

.input-code-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  margin-bottom: 13px;

}

.pollCode-input-box {
  max-width:65px;
  max-height: 70px;
}

@media only screen and (min-width: 481px) and (max-width: 950px) {
  .pollCode-input-box {
    max-width: 50px;
    max-height: 60px;
  }

}