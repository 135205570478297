.mask {
    position: fixed;
    z-index: 1200;
    inset: 1.5rem;
    pointer-events: none;
}

.toastContainer {
    position: absolute;
    width: 100%;
    transition: all 0.5s ease-out;
}


.verticalLayout {
    display: flex;
    flex-direction: column;
}

.horizontalLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    .toast {
        max-width: 90% !important;
    }
}

.toast {
    max-width: 50%;
    pointer-events: auto;
    background-color: #ffffff;
    box-shadow: 0 10px 15px -3px #0000001A, 0 4px 6px -4px #0000001A;
    border-radius: 6px;
    overflow: hidden;

}

.toastBody {
    justify-content: start;
    padding: 15px 30px 15px 20px;
    min-width: 230px;
}

.progressBar{
    position: relative;
    height: 4px;
    z-index: 1;
}

.progressBar.info {
    background-color: #006B9B;
}

.progressBar.success {
    background-color: #5D9962;
}

.progressBar.warning {
    background-color: #BD8700;
}

.progressBar.error {
    background-color: #FF5948;
}

.successIcon {
    height: 1.5rem;
    width: 1.5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("./images/success.png");
}

.errorIcon {
    height: 1.5rem;
    width: 1.5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("./images/fail.svg");
}

.closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
    height: 24px;
    width: 24px;
    background-color: #FCFCFC;
    border-radius: 12px;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon:hover {
    cursor: pointer;
    background-color: #CFCFCF;
}

.msg {
    flex: 1;
    max-width: 80%;
    font-size: 12px;
    color: #333333;
}

.closeBtn {
    border-radius: 0.188rem;
    padding: 0.25rem 0.75rem;
    background-color: #ffffff;
    color: #454242;
    text-align: center;
    font-weight: bolder;
    border: 2px solid #454242;
}

.closeBtn:hover {
    background-color: #454242;
    color: #ffffff;
}