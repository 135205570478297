.container {
    position: relative;
    font-family: Geomanist;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    background-color: #FFFFFF;
    cursor: default;
}

.presenter {
    position: relative;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
}

.opened {
    border-radius: 3px 3px 0 0 ;
}

.invalid {
    border: 1px solid #BF0033;
}

.disabled{
    opacity: .5;
    background-color: #E6E6E6;
}

.disabled:hover{
    cursor: not-allowed;
}

.presenterText {
    flex: 1;
}

.menuList {
    position: absolute;
    width: 100%;
    max-height: 100px;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-color: #D0D0D0;
    z-index: 100;
}

.menuList::-webkit-scrollbar-track {
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: #D0D0D0;
}


.menuItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-style: solid;
    border-width: 1px; 
    border-color: #D0D0D0;
    padding: 10px;
    background-color: #FFFFFF;

}

.menuItem:last-child {
    border-top: none;
    border-radius: 0 0 3px 3px;
}

.menuItem:not(:first-child):not(:last-child){
    border-top: none;
}

.menuItem:first-child {
    border-top: 1px solid #D0D0D0;
    border-radius: 0 0 3px 3px;
}

.menuItemText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem:hover:not(.selected) {
    background-color: #f3f3f3;
}

.selected {
    background-color: #D9EFF4;
}

.iconContainer {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: default;
}
.invalidIcon {
    position: absolute;
    margin-left: 3px;
    width: 25px;
    height: 25px;
    background: url("../../images/invalidIcon.svg");
    background-size: 25px 25px;
}

.invalidIcon:hover {
    background: url("../../images/invalidIcon_hover.svg");
}

