.background {
  padding: 10px 0 10px 0;
  margin: 10px 0 10px 0;
  border-left: 6px solid #FFFFFF;
  overflow-x: hidden;
}

.background.active {
  background-color: rgba(255, 215, 134, 0.2);
  border-left: 6px solid #FFD786;
  border-radius: 4px;
}

.background:hover {
  background-color: rgba(255, 215, 134, 0.2);
  border-left: 6px solid #FFD786;
  border-radius: 4px;
}

.shiftQText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  animation-name: shiftQAnm;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

@keyframes shiftQAnm {
  from {opacity: 0;}
  to {opacity: 1;}
}

.share-body {
  /* background-color: #FFD786; */
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.share-body-page {
  background-color: #FFFFFF;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.bot-row {
  height: 60px;
  line-height: 60px;
}

.box-bord-round {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px 5px 0 0;
}

.box-bord {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  height: 60px;
  line-height: 60px;
}

.bord-btn{
  width: 100%;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding-right: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.set-right {
  float: right;
  justify-content: right;
}

.top-row {
  height: 44px;
  padding: 10px;
}

.poll-title-format {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  color: rgba(90, 90, 90, 1);
  align-content: center;
  text-align: center;
  margin-top: 1.425rem;
  width: 100%

}

.poll-description-format {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 27px;
  align-content: center;
  text-align: center;
  width: 100%
}

.center-h-v {
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
}

.serialNum {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}

.serial-back{
  flex-direction: column;
  display: flex;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap !important;
}

div[draggable] > .row {
  flex-wrap: nowrap !important;
}

.question-tab-body {
  margin-top: 35px;
  margin-left: 58px;
  margin-bottom: 32px;
  margin-right: 48px;
  word-wrap: break-word !important;
}

.question-tab-body-gb {
  word-wrap: break-word !important;
}

.question-title {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #000000 !important;
  word-break: break-word;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.875rem !important;
}

.question-title-FITB {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: #000000;
  margin-bottom: 10px;
  word-break: break-word;
}

.MCSS-ans-c {
  border: 2px solid #0A820E;
  writing-mode: horizontal-tb;
  overflow: hidden;
  background-color: rgba(10, 130, 14, 0.05);
  height: 50px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #0A820E;
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.MCSS-ans {
  height: 50px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.FITB-ans-blank{
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  margin: 5px 5px 5px 0;
}

.FITB-ans{
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  margin: 5px 5px 5px 0;
  display: block;
}

.FITB-row{
  margin-bottom: 10px;
}

.FITB-ans-b{
  display: block;
  margin-left: 75px;
}

.ratio-text {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  transform: translateY(-5px);
}

.participants-text {
  padding: 10px;
  margin: 100px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 19px;
  height: 19px;
  text-align: center;
  color: #000000;
  transform: translateY(5px);
}

.legend-text {
  padding: 10px !important;
  margin-left: 40px !important;
  font-family: 'Geomanist' !important;
  font-style: normal !important;
  font-weight: 700!important;
  font-size: 18px !important;
  line-height: 19px !important;
  letter-spacing: 0.03em !important;
  color: #000000
}

.legend-stuff {
  transform: translateX(50px);
}

/* Tool Tips */
.icon{
  position: relative;
  display: inline-block;
}

.icon .tooltiptext {
  visibility: hidden;
  color:  #000000;
  background-color: #FFFFFF;
  border: 1px solid #c6c6c6;
  text-align: center;

  padding: 5px;
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  height: 40px;
  line-height: 30px;
}

.icon:hover .tooltiptext {
  visibility: visible;
}

/*timer*/
.timer-count {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  vertical-align: middle;
  text-align: center;
  color: #056808;
  padding: 5px;
  margin-right: 0;
}

.pollPanelTimerText.urgent {
  color: #C93E3E;
}

.timerMargin {
  margin-right: 10px;
}

.timer-logo {
  padding: 2%;
  height: 40px;
  width: 40px;
}

.timer-holder-share {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  border: #A8A8A8 2px solid;
  border-radius: 10px;
  width: 220px;
  height: 32px;
}

.timer-outer {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
}

.img-container {
  margin-top: 8px !important;
  margin-right: 30px;
}

.img-container-question {
  margin: 30px 0px 0px 0px;
}

.img-container-share {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 10px;
  margin-right: 4px;
}

.question-image-container {
  margin-top: 8px !important;
  display: flex;
  justify-content: center;
}

.question-image {
  max-width: min(40vw, 720px) !important;
  max-height: min(22.5vw, 405px) !important;
}

.coordinate-close:hover {
  cursor: pointer;
}

.question-body {
  flex: 40;
  margin-top: 20px !important;
  margin-right: 39px !important;
  margin-left: 25px !important;
  margin-bottom: 16px !important;
  font-family: 'Geomanist' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 18px !important;
  letter-spacing: 0.035em !important;
  color: #000000;
  width: 45px;
  word-wrap: break-word;
  transition: color 1s ease;
}

.question-body-on {
  color: white;
  transition: color 1s ease;
}

.question-index {
  flex: 4 !important;
  text-align: center !important;
  margin-top: 20px !important;
  margin-left: 14.6px !important;
  margin-bottom: 23px !important;
  font-family: 'Geomanist' !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 22px !important;
  letter-spacing: 0.035em !important;
  color: #000000;
  transition: color 1s ease;
}

.question-index-on {
  color: white;
  transition: color 1s ease;
}

.question-container {
  flex: 3 !important;
  background: #e6f2f7 !important;
  overflow-y: auto !important;
  height: calc(100vh - 52px) !important;
}

.question-tab-select {
  flex: 1.5 !important;
  background-color: #E6F2F7;
  transition: background-color 1s ease;
}

.question-tab-select-on {
  background-color: #25395b;
  transition: background-color 1s ease;
}

.poll-title-icon-container {
  margin-left: 20px !important;
}

.published-poll-data {
  flex: 6 !important;
  background-color: #fbfbfb;
  overflow-y: auto !important;
  height: calc(100vh - 52px);
}

.poll-title-container {
  margin-top: 44px !important;
  margin-bottom: 30px !important;
  font-family: "Poppins" !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24.6885px !important;
  line-height: 37px !important;
  color: #000000;
}

.expired-question-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tab-results-container {
  display: flex;
  flex-direction: column;
  margin-top: 51px;
  margin-right: 88px;
  margin-left: 85px;
  box-shadow: 1.88824px 1.88824px 25.5376px 0.944119px rgba(0, 0, 0, 0.05);
  border-radius: 7.55295px;
}


.tab-participants-container {
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  margin-right: 88px;
  margin-left: 85px;
  margin-bottom: 52px;
  background: #FFFFFF;
  box-shadow: 1.88824px 1.88824px 25.5376px 0.944119px rgba(0, 0, 0, 0.05);
  border-radius: 7.55295px;
}

.tab-results-title {
  display: flex;
  justify-content: space-between;
  background: #FCFCFC;
  border: 1.02151px solid #EFEFEF;
  border-radius: 7.55295px 7.55295px 0px 0px;
  flex: 1;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #000000;
}

.participants-title {
  margin-top: 30px ;
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 21.1526px;
  line-height: 21px;
}

.chart-data {
  display: flex;
  min-height: 55px;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  border-radius: 10px;
  letter-spacing: 0.035em;
}

.question-title-container {
  display: flex;
  min-height: 70px;
  flex: 10;
  background-color: #e6f2f7;
  transition: background-color 1s ease;
}

.question-title-container-on {
  background-color: #0277AD;
  transition: background-color 1s ease;
}

.outer-question {
  display: flex;
}

.outer-question-on {
  display: flex;
  background-color: #e6f2f7 !important;
}

.outer-question:hover {
  background-color: #0277AD;
}

[class*='panelSpin'] [class*='ant-spin-container'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pollPanel {
  height: 12%;
  min-height: 90px;
  width: 100%;
  display: flex;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 1.66994px 1.66994px 22.5852px 0.834969px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.pollPanelTitle {
  margin-top: 10px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.035em;
  color: #000000;
  word-break: break-word;
  margin-right: 7px;
}

.pollPanelTitleSmall {
  margin-top: 10px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.035em;
  color: #000000;
  word-break: break-word;
  margin-right: 7px;
}

@media only screen and (max-width: 1400px) {
  .pollPanelTitleSmall {
    font-size: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1500px) {
  .pollPanelTitle {
    font-size: 20px;
    line-height: 20px;
  }
}

.pollPanelSubtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #000000;
}

.pollPanelTimerText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.035em;
  padding: 6px;
  color: #007D00;
}

.smallPanelBody {
  height: 150px;
  display: flex;
  flex-direction: column;
  padding: 25px 20px;

  background: #FFF;
  border-radius: 8px;
  animation-name: panelAnimation;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.smallPanelBodyNew {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  animation-name: panelAnimation;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.browserText {
  color: #000;
  font-family: Geomanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.userInfoTitle {
  color: #000;
  text-align: center;
  font-family: Geomanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.525px;
  margin-left: 25px;
  margin-right: 25px;
}

.smallPanelBody2 {
  height: 293px;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;

  background: #FFF;
  border-radius: 8px;
}

@keyframes panelAnimation {
  from {height: 293px;}
  to {height: 50px;}
}

@keyframes panelAnimation2 {
  from {height: 50px;}
  to {height: 293px;}
}

.smallPanelTitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #262626;
}

.containerVertical {
  margin-bottom: 20px;
}

.smallPanelSubtitle {
  margin-top: 12px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #727272;
}

.responsePanelText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #727272;
}

.responselegendcircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
}

.responsePanelPercent {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #262626;
}


.slidecontainer {
  width: 100%;
  background: #F2F2F2;
  border-radius: 16px;
  height: 10px;
}

.slidecontainerSmall {
  width: 100%;
  background: #F2F2F2;
  border-radius: 12px;
  height: 6px;
}

.slider {
  width: 0px;
  height: 10px;
  font-size: 1px;
  color: #01BEA3;
  background: #01BEA3;
  border-radius: 16px;
  animation-name: sliderAnimation;
  animation-duration: 2s;
}

.sliderSmall {
  width: 0px;
  height: 6px;
  font-size: 1px;
  color: #01BEA3;
  background: #01BEA3;
  border-radius: 12px;
  animation-name: sliderAnimation;
  animation-duration: 2s;
}

.slider2 {
  width: 0px;
  height: 10px;
  font-size: 1px;
  color: #0277AD;
  background: #0277AD;
  border-radius: 16px;
  animation-name: sliderAnimation;
  animation-duration: 2s;
}

.slider2Small {
  width: 0px;
  height: 6px;
  font-size: 1px;
  color: #0277AD;
  background: #0277AD;
  border-radius: 12px;
  animation-name: sliderAnimation;
  animation-duration: 2s;
}

@keyframes sliderAnimation {
  from {width : 0px}
}

.responsePanelNumbers {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.resultsCont1 {
  height: 150px !important;
}

.resultsCont2 {
  min-height: 150px;
}

.participantsPanelText {
  font-family: Geomanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.participantsPanelTextSmall {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.pollCodePanelText {
  margin-top: 16px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #727272;
  /* text-align: justify; */
}

.pollCodePanelTextSmall {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.035em;
  margin-bottom: 8px;
  /* text-align: justify; */
}

.pollCodePanelTitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #000000;
}

.pollCodePanelSubtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.035em;
}

.pollCodePanelSubtitleSmall {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.035em;
}

.linkPanelSubtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.035em;
  text-decoration: underline;
}

.linkPanelSubtitleSmall {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 14.175px;
  line-height: 14px;
  letter-spacing: 0.035em;
  text-decoration: underline;
}

.pollCodeCopyButton {
  border: double 2px transparent;
  border-radius: 6px;
  margin: auto;
  padding: 5px 10px;
  border-image: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%);
  border-image-slice: 1;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.pollCodePanelBody {
  margin-top: 10px;
  height: calc(100% - 25px);
}

.activityFeedSmallText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #727272;
  margin-bottom: 0px;
}

.activityFeedNameText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #262626;
  margin-bottom: 4px;
  word-break: break-all;
}

.activityFeedBody {
  margin-top: 10px;
  width: 100%;
  height: 100%;
}

.activityFeedTab {
  align-self: normal;
  width: 5px;
  background: linear-gradient(152.41deg, #008EFF -25.31%, #EA4C89 136.45%);
  margin-right: 25px;
}

.outerStudent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  min-height: 40px;
}

.firstStudent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  min-height: 40px;
  opacity: 0;
  animation: firstStudent 2s ease-in-out forwards;
}

@keyframes firstStudent {
  from {opacity: 0; }
  to {opacity: 1; }
}

.questionPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 40px 35px;
  overflow-x: auto;
}

.questionPanelOptionsContainer {
  display: flex;
  min-height: 40px;
  margin-bottom: 30px;
  border-radius: 10px;
  align-items: center;
  padding: 10px 10px;
}

.questionPanelTitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 118%;
  text-align: justify;
  letter-spacing: 0.035em;
  color: #000000;
  /* margin-bottom: 30px; */
  margin-right: 4px;
  word-break: break-word;
  word-wrap: break-word;
}

.questionTitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 118%;
  text-align: justify;
  letter-spacing: 0.035em;
  color: #000000;
  /* margin-bottom: 30px; */
  /* margin-right: 4px; */
  word-break: break-word;
  word-wrap: break-word;
}

.gradientWrapQuesType {
  display: flex;
  flex-wrap: wrap;
  border-radius: 47px;
  background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  margin-left: 20px;
  padding: 2px;
  position: relative;
}

.questionPanelQuestionType {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.questionPanelQuestionTypeContainer {
  text-align: center;
  white-space: nowrap;
  color: #000000;
  height: 40px;
  width: 195px;
  border-radius: 47px;
  background-color: #f2eef6;
  padding: 0 1.5vw;
}

.questionPanelQuestionTypeContainerTimer {
  text-align: center;
  white-space: nowrap;
  color: #000000;
  height: 36px;
  border-radius: 47px;
  background-color: #f2eef6;
  padding: 0 1.5vw;
}

.questionPanelTitleFITB {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  text-align: justify;
  letter-spacing: 0.035em;
  color: #000000;
  word-wrap: break-word;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.questionPanelOptionsText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.035em;
  color: #000000;
  margin-right: 25px;
}

.questionPanelOptionsTextMC {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.035em;
  color: #000000;
  margin-right: 25px;
  z-index: 1;
}

.questionPanelData {
  display: flex;
  min-width: 35px;
  min-height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 135px;
  border: 1.013px solid var(--Linear, #BA54A1);
  background: #F6EEF6;
  margin-left: 10px;
  z-index: 1;
}

.questionPanelNumber {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 19.2px;

  text-align: right;
  letter-spacing: 0.675px;
  color: #000000;
  z-index: 2;
}

.questionPanelTitleText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #000000;
  word-wrap: break-word;
  word-break: break-word;
  margin-right: 10px;
}

.questionPanelAnswersText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #000000;
  word-wrap: break-word;
  margin-right: 10px;
  word-break: break-all;
}

.questionPanelAnswersTextMC {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #000000;
  word-wrap: break-word;
  margin-right: 10px;
  word-break: break-word;
  z-index: 1;
}

.questionPanelCorrectText {
  font-weight: 700;
  color: #268726;
}

.questionPanelAllQSelect {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.035em;
  color: #000000;
  margin-right: 25px;
}

.questionPanelTotalQuestions2 {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.035em;
  color: #939393;
  margin-left: 6px;
  white-space: nowrap;
}

.questionPanelTotalQuestions {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.035em;
  background: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.questionPanelTotalQuestionsPoll {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.035em;
  background: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.YuJa-switch {
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
}

.YuJa-switch .form-check-input{
  float: none !important;
  margin: 0 5px 0 0 !important;
}

.YuJa-switch .form-check-input:checked {
  background-color: #0B6F85 !important;
  border-color: #0B6F85 !important;
}

.YuJa-switch .form-check-input:focus {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

.YuJa-switch .form-check-input:not(:checked) {
  background-color: #E6E6E6 !important;
  border-color: #E6E6E6 !important;
  background-image: url("../../images/toggleOffIcon.svg")!important;
}

/* .YuJa-switch .form-check-input:not(:checked):focus {
  border: 1px solid rgba(0, 0, 0, 0.75) !important;
  background-image: url("../../images/toggleOffIcon.svg")!important;
} */

.YuJa-switch .form-check-label{
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 500;
  /* font-size: 15px; */
  /* line-height: 15px; */
  color: #000;
}

@media only screen and (max-width: 1400px) {
  .questionPanelQuestionType {
    font-size: 12px;
    line-height: 12px;
  }
  .questionPanel .YuJa-switch {
    font-size: 12px;
  }
  .questionPanel .questionPanelTotalQuestions2 {
    font-size: 18px;
    line-height: 18px;
  }
  .questionPanel .questionPanelTotalQuestions {
    font-size: 20px;
    line-height: 20px;
  }
}


.questionPanelCollapseText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  background: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%),
              linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%) bottom 0px left 0/100% 1.5px no-repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text,padding-box;
  background-clip: text;
  cursor: pointer;
}

.fitbBlankContainers {
  display: flex;
  border: 3px solid #939393;
  border-radius: 12px;
  min-height: 50px;
  min-width: 100px;
  margin-top: 10px;
  margin-right: 5px;
}

.fitbBlankWrap {
  text-align: center;
  word-break: break-all;
  visibility: hidden;
  color: #000000;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  margin: auto;
  padding: 15px;
}

.saAnswerContainer {
  border: 3px solid #000000;
  border-radius: 12px;
  margin-bottom: 10px;
  height: 200px;
}

.saAnswerText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #000000;
  padding: 22px 28px;
  word-break: break-all;
}

.shuffleAnswerToolTip:hover::after {
  display: block;
  position: relative;
  right: 140px;
  bottom: 30px;
  /* right: 100px;
  bottom: -10px;*/
  text-align: center;
  background-color: #000000;
  color: white;
  width: 130px;
  padding: 7px 8px;
  content: "Shuffle Answer";
}

@media only screen and (max-width: 1375px) {
  .shuffleAnswerToolTip:hover::after {
      width: 70px;
      line-height: 19px;
      right: 80px;
  }
}


.cardFront,
.cardBack {
  border: 3px solid #000000;
  border-radius: 12px;
  height: 200px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #000000;
  padding: 22px 28px;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  position: absolute;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: transform 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-y: auto;
}
.cardBack {
  transform: perspective(5000px) rotateY(180deg);
}

.cardBack.flipped {
  transform: perspective(5000px) rotateY(0deg);
}

.cardFront {
  transform: perspective(5000px) rotateY(0deg);
}

.cardFront.flipped {
  transform: perspective(5000px) rotateY(-180deg);
}

.sharePollContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  /* min-height: 700px; */
  /* min-width: 1230px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 2% 2% !important;
}

.sharePollBodyContainer {
  display: flex;
  justify-content: space-between;
  height: 82%;
  width: 100% !important;
  overflow: auto;
}

.viewQuestionsContainer {
  display: flex;
  width: 45%;
  margin-right: 35px;
}

.viewQuestionsContainer2 {
  display: flex;
  width: 100%;
  margin-right: 35px;
}

.viewQuestionsContainerNarrow {
  display: flex;
  width: 100%;
  margin-right: 25px;
}

.viewResultsContainer {
  display: flex;
  width: 55%;
  flex-direction: column;
}

.viewResultsContainer2 {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 350px;
}

.viewResultsContainerNarrow {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 375px !important;
  /* padding-left: 25px; */
  /* height: 100%; */
  /* position: absolute; */
  /* right: 0; */

  /* animation-name: resultsExpand;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}

.viewResultsContainerCollapsed {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 0px !important;
  z-index: 101;
  /* position: absolute; */
  /* right: 0; */
  /* transform: translate(100%); */

  /* animation-name: resultsCollapse;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}

/* @keyframes resultsExpand {
  from {max-width: 0px;}
  to {max-width: 350px;}
}

@keyframes resultsCollapse {
  from {width: 350px;}
  to {width: 0px;}
} */

.viewResultsCollapsedControl {
  position: absolute;
  right: 25px;
  top: 50%;
  /* z-index: 100; */
}

.viewResultsControl {
  height: 100%;
  display: flex;
  align-items: center;
  /* position: absolute; */
  /* right: 375px; */
  /* top: 50%; */
  z-index: 101;
}

.viewResultsRow {
  display: flex;
  justify-content: space-between;
  height: 50%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.gradientWrap {
  display: flex;
  flex-wrap: wrap;
  border-radius: 7.5px;
  background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  cursor: pointer;
}

.gradientWrap2 {
  display: inline-block;
  flex-wrap: wrap;
  border-radius: 7.5px;
  background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  cursor: pointer;
}

.gradientWrapPoll {
  display: flex;
  border-radius: 7.5px;
  background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  cursor: pointer;
  height: 52px;
}

.grayedGradientWrap {
  display: flex;
  flex-wrap: wrap;
  border-radius: 7.5px;
  background: #d6d6d6;
  height: 52px;
}

.triangleTextPoll {
  visibility: hidden;
  display: block;
  position: absolute;
  right: 32px;
  margin-top: 45px;
}

.infoTextPoll:empty {
  display: none;
}

.infoTextPoll {
  display: block;
  position: absolute;
  right: 20px;
  margin-top: 63px;
  padding: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  background-color: #000000;
}

.publishToolTip:hover::before {
  display: block;
  position: absolute;
  transform: translateY(50px);
  margin-top: 2.5px;
  padding: 8px 0px;
  width: 69px;
  height: 58px;
  line-height: 20px;
  text-align: center;
  background-color: #000000;
  color: white;
  content: "Share Poll";
}

.publishQToolTip:hover::before {
  display: block;
  position: absolute;
  transform: translateY(50px);
  margin-top: 2.5px;
  padding: 8px 0px;
  width: 69px;
  height: 58px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  background-color: #000000;
  color: white;
  content: "Share Question";
}

.stopToolTip:hover::before {
  display: block;
  position: absolute;
  transform: translateY(50px);
  margin-top: 2.5px;
  padding: 8px 0px;
  width: 69px;
  height: 58px;
  line-height: 20px;
  text-align: center;
  background-color: #000000;
  color: white;
  content: "Stop Sharing";
}

.endToolTip:hover::before {
  display: block;
  position: absolute;
  transform: translateY(50px);
  margin-top: 2.5px;
  padding: 8px 0px;
  width: 69px;
  text-align: center;
  background-color: #000000;
  color: white;
  content: "End Poll";
}

.previousToolTip:hover::before {
  display: block;
  position: fixed;
  transform: translateY(50px);
  margin-top: 2.5px;
  padding: 8px 0px;
  width: 69px;
  text-align: center;
  background-color: #000000;
  color: white;
  content: "Previous";
}

.nextToolTip:hover::before {
  display: block;
  position: fixed;
  transform: translateY(50px);
  margin-top: 2.5px;
  padding: 8px 0px;
  width: 69px;
  text-align: center;
  background-color: #000000;
  color: white;
  content: "Next";
}

.gradientBtn {
  border-radius: 5px;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 8px 20px;
}

.gradientBtnSmall {
  border-radius: 4px;
  text-align: center;
  width: 80px;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 8px;
  display: inline-block
}

.gradientBtnView {
  border-radius: 5px;
  text-align: center;
  width: 190px;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 12.5px 0px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
}

.gradientBtnPublish {
  border-radius: 5px;
  text-align: center;
  width: 190px;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 11px 0px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
}

.grayedGradientBtnPublish, .grayedGradientBtnStop {
  border-radius: 5px;
  text-align: center;
  width: 190px;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 11.5px 0px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
}

.grayedGradientBtnPublish * {
  fill: #d6d6d6;
}

.grayedGradientBtnStop {
  stroke: #d6d6d6;
}

.gradientBtnArrows {
  border-radius: 5px;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 8px 20px;
}

.grayedBtnArrows {
  border-radius: 5px;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 8px 20px;
}

.gradientBtnStop {
  border-radius: 5px;
  text-align: center;
  width: 190px;
  white-space: nowrap;
  color: #000000;
  background-color: #FFFFFF;
  margin: 2.5px;
  padding: 11px 0px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
}

.gradientBtnPublish:hover , .gradientBtnArrows:hover, .gradientBtnArrows:focus, .gradientBtnStop:hover, .gradientBtnView:hover, .gradientBtnView:focus, .gradientBtnStop:focus, .gradientBtnPublish:focus {
  background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  color: white;
}

#buttonsContainer:hover + .infoTextPoll {
  visibility: hidden;
}

#buttonsContainer:hover ~ .triangleTextPoll {
  visibility: hidden !important;
}

.gradientBtnView:hover * {
  fill: #FFFFFF !important;
  stroke: #FFFFFF !important;
}

.gradientBtnView:focus * {
  fill: #FFFFFF !important;
}

.gradientBtnPublish:hover * {
  fill: #FFFFFF !important;
}

.gradientBtnPublish:focus * {
  fill: #FFFFFF !important;
}

.gradientBtnArrows:hover * {
  stroke: #FFFFFF !important;
}

.gradientBtnArrows:focus * {
  stroke: #FFFFFF !important;
}

.gradientBtnStop:hover * {
  stroke: #FFFFFF !important;
}

.gradientBtnStop:focus * {
  stroke: #FFFFFF !important;
}

.shareIconHover {
  filter: invert(1);
}

.minimizedPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;

  background: #FFFFFF;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.downloadIcon {
  position: absolute;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.resultAnimation {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}


@keyframes reaction-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.reaction-animation {
  animation: reaction-animation 1s;
}

/* Define styles for the custom-tooltip class */
.customTooltip {
  color: #000;
  font-family: Geomanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: white;
  border:#000 1px solid;
  padding: 5px;
}

.userDetails {
  color: #262626;
  font-family: Geomanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.engagementText {
  color: #000;
  font-family: Geomanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.609px;
  letter-spacing: -0.3px;
}

.engagementButton {
  display: flex;
  width: 101.7px;
  height: 35px;
  padding: 4.605px 9.211px;
  justify-content: center;
  align-items: center;
  gap: 5.526px;
  border-radius: 6.908px;
  border: 1.228px solid #006B9B;
  background: rgba(0, 107, 155, 0.10);
  color: #000;
  font-family: Geomanist;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ExpandIcon{
  background-image: url("./images/ExpandSideBar.svg");
  width: 48px;
  height:48px; 
  margin-left: 20px;
}

.ExpandIcon:hover {
  background-image: url("./images/ExpandSideBarHovered.svg");
}

.CollapseIcon {
  background-image: url("./images/CollapseSideBar.svg");
  width: 48px;
  height:48px; 
  margin-left: 20px;
}

.CollapseIcon:hover {
  background-image: url("./images/CollapseSideBarHovered.svg");
}