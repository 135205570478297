.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Geomanist;
}

.title {
    font-size: 36px;
    font-weight: 700;
    margin: 50px 0;
    color: #42296E;
}

.stepsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.steps {
    margin-bottom: 15px;
}

.link {
    color: #006DC7;
    font-weight: 800;
}

.link:hover {
    cursor: pointer;
}

.card {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
    font-family: Geomanist;
    font-size: 20px;
}


.cardTitleBox {
    border-radius: 6.68px 6.68px 0px 0px;
    border: 0.903px solid #EFEFEF;
    background: #F4F5F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cardTitleText {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.cardBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 20px !important;
}

@media only screen and (max-width: 500px) {
    .title{
        font-size: 28px;
    }

    .card {
        font-size: 14px;
    }

    .cardTitleText {
        font-size: 20px;
    }

    .cardBody {
        padding: 20px 15px !important;
    }
}

