.expShadow {
  width: 92%;
  margin-top: 3.5rem;
}

.expContainer {
  width: 62%;
  margin-top: calc(2vw);
  margin-bottom: calc(3vw);
  padding: 4vw 0 2vw 0;
}

.expCenterLayout {
  display: flex;
  height: fit-content;
  justify-content: center;
  flex-wrap: wrap;
}

.correctOptionText {
  color: #000 !important;
}


.normalOptionText {
  color: #000 !important;
}


@media only screen and (min-width: 250px) and (max-width: 800px) {
  .expContainer {
      width: 85vw !important;
  }
  .expIllustration {
      height: 48vw !important;
      width: 80vw !important;
  }

}

@media only screen and (max-height: 800px) {
  .expShadow {
      margin-bottom: 3rem !important;
  }
}

.expIllustration {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: rgba(0,0,0,0);
  height: 14rem;
  padding: 0;
  width: 23rem;
  border-bottom: 0;
  background-image: url('./images/dashboard illustration.svg');
}

@media only screen and (max-width: 1200px) {
  .shadow {
    height: calc(100vh - 75px) !important;
    position: absolute;
    top: 0;
    width: 100vw;
    margin: 0;
    box-shadow: none;
  }

  .optionText {
    max-width: 70vw;
  }
}

@media only screen and (min-width: 1200px) {
  .shadow {
    width: 90vw;
    margin-top: 1rem;
  }
  .optionText {
    max-width: 40vw;
  }
}

.container {
  width: 80%;
  margin-top: calc(1.5vw);
  margin-bottom: calc(1.5vw);
  padding: 4vw 0 2vw 0;
}

@media only screen and (max-width: 480px) {
  .containerTop {
    width: calc(100% - 48px);
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 90px;
    height: fit-content;
  }
  
  .containerBottom {
    width: 100%;
    min-height: 75vh;
    padding: 0px 21px 0px 21px;
    position: relative;
    box-shadow: none;
    border: none;
  }

  .containerCountdown {
    height: 45px;
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0;
    position: relative;
    box-shadow: none;
    border: none;
    display: block;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1200px) {
  .containerTop {
    width: calc(100% - 48px);
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 90px;
    height: fit-content;
  }
  
  .containerBottom {
    width: 100%;
    min-height: 65vh;
    padding: 0px 61px 0px 61px;
    position: relative;
    box-shadow: none;
    border: none;
  }

  .containerCountdown {
    height: 45px;
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0;
    position: relative;
    box-shadow: none;
    border: none;
  }
}

@media only screen and (min-width: 1200px) {
  .containerTop {
    width: calc(100% - 48px);
    margin: 1.5vw 0vw 1.5vw 0px;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 90px;
    height: fit-content;
  }
  
  .containerBottom {
    width: 100%;
    min-height: 65vh;
    background: #FCFBFF;
    padding: 0px 61px 0px 61px;
    position: relative;
  }
}

.resultsContainer {
  width: 87.5%;
  margin: 1.5vw 0vw 1.5vw 0vw;
  padding: 1vw 6vw 1vw 6vw;
}

.centerLayout {
  display: flex;
  height: fit-content;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #FCFBFF;
}

.pollShareAllLayOut {
  width:100% ;
  height: 100%; 
  margin-top: 0px;
  background-color: #FCFBFF;
}

.lottieContainer {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: -100px; */
  /* margin: -100px; */
  /* background-color: #123456; */
  z-index: -1;
}

.lottieContainer:hover {
  /* background-color: #654321; */
  cursor: default !important;
}

.endedPollBackground {
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 75px);
  min-height: 590px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3%;
}

.endedPollTitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #0277AD;
  margin-bottom: 50px;
}

.endedPolllSubtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-bottom: 46px;
}

.endedPolllSubtext {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #545454;
  margin-bottom: 20px;
}

.endedPollText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #545454;
}

@media only screen and (min-width: 1020px) and (max-width: 1250px) {
  .endedPollText {
    font-size: 23px;
  }
  .endedPolllSubtext {
    font-size: 23px;
  }
  .endedPolllSubtitle {
    font-size: 38px;
  }
  .endedPollTitle {
    font-size: 38px;
  }
}

@media only screen and (max-width: 1019px) {
  .endedPollText {
    font-size: 18px;
  }
  .endedPolllSubtext {
    font-size: 18px;
  }
  .endedPolllSubtitle {
    font-size: 28px;
  }
  .endedPollTitle {
    font-size: 28px;
  }
}

.endedPollIcon {
  margin-bottom: 44px;
}


@media only screen and (max-width: 450px) {
  .endedPollIcon {
    width: 250px;
    height: 150px;
    margin-bottom: 10px;
  }

  /*.option {*/
  /*  padding :0 !important; */
  /*}*/
}

.chartContainer {
  width: 100%;
  margin-top: 20px;
  font-family: 'Geomanist';
  display: flex;
  flex-direction: column;
}

.chartContainerSharePoll {
  width: 100%;
  font-family: 'Geomanist';
  display: flex;
  flex-direction: column;
}

.optionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.option {
  display: flex;
  align-items: center;
  flex: auto;
  min-height: 55px;
  position: relative;
  height: 100%;
  padding: 5px 20px;
  overflow: hidden;
}

.optionIndex {
  position: relative;
  z-index: 1;
  font-weight: 700;
}

.optionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 20px;
  position: relative;
  z-index: 1;
}

.optionSelectedMark {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  z-index: 1;
}

.optionCountBar {
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #CACACA;
  border-radius: 10px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.optionCountBarMC {
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 100%;
  background-color: rgb(244, 244, 244);
  border-radius: 10px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.optionCountBar.correct{
  background-color: var(--foundation-violet-light-active, #C4BDD2);
}

.countValueContainer {
  z-index: 1;
  margin-left: 20px;
  font-weight: 700;
  justify-content: end; 
  display: flex;
}

.optionTextToolTip {
  background-color: #6C6C6C;
  color: #FFFFFF;
  font-family: 'Geomanist';
  padding: 10px;
  opacity: 0.8;
  z-index: 1;
  border-radius: 5px;
}

.optionTextToolTip::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #6C6C6C;
  bottom: -7px;
}

.maxWidth {
  width: 100% !important;
}

.LoginSelect {
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
  font-family: 'Geomanist';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  display: grid; 
  grid-template-columns: 80% 20%;
  font-family: 'Geomanist';
  color: #000;
}


.choiceoption {
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
  font-family: 'Geomanist';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: block;
  font-family: 'Geomanist';
  color: #000;
  background-color:#FAFCFD ;
  padding-left: 15px;
  align-items: center;
  display: grid; 
  border-top: none;
  cursor: pointer;
}

.choiceoption:hover {
  background-color:#CACACA; 
}

.MatchingNarrow {
  flex-direction: column;
  border-top: 2px dashed grey; 
}

.MatchingNarrow {
  flex-direction: column;
  border-top: 2px dashed grey; 
}

.onlineIcon {
  background-color: #E6F3E6;
  color: var(--color-guide-successcolors-400, #005C00);
  font-family: 'Geomanist';
  font-weight: 700;
  font-size: 21px;
  padding: 7.5px 17.5px 7.5px 17.5px;
  justify-content: center;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: default;
  gap: 10px; 
  border-radius: 47px;
  width: 150px;
  height: 45px;
  border: 2.5px solid var(--color-guide-successcolors-400, #005C00);
  background: var(--color-guide-successcolors-50, #E6F3E6);
}


.offlineIcon {
  background-color: #FBEAEB;
  color: #991F27;
  font-family: 'Geomanist';
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #991F27;
  border-radius: 20px;
  padding: 5px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: default;
  gap: 5px;
}


.mobileIcon {
  padding: 5px 5px;
}

@media only screen and (max-width: 1200px) {
  .onlineIcon, .offlineIcon {
    font-size: 14px;
    gap: 2px;
    display: flex;
    width: 28px;
    height: 28px;
    padding: 3.9px;
    justify-content: center;
    align-items: center;
    gap: 3.28px;
    flex-shrink: 0;
    border-radius: 24.44px;
  }
}


