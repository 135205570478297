.tableContainer {
    justify-content: center;
    width: 100%;
    height: 50vh; 
    overflow-y: auto;
}

.tableContainer table {
    font-family: 'Geomanist';
    table-layout: fixed;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center !important;
    vertical-align: middle !important;
}

.tableContainer tr > th {
    background-color: transparent!important;
}

.tableContainer tr {
    border: 0.031rem solid rgba(208, 208, 208, 1)!important;
}
.tableContainer table thead, tbody, tfoot {
    border-top: 0 !important;
}

.tableContainer table > thead > tr > th{
    font-weight: 400 !important;
    font-size: 14px;
    font-family: "Geomanist";
    padding: 0px;
    color: #000000;
}

.tableContainer .tableBtnUnit {
    line-height: 0 !important;
    padding: 0 !important;
}

.tableContainer .tableBtn {
    display: inline-block;
    width: 25px;
    height: 25px;
}

.tableContainer .tableBtn:hover {
    cursor: pointer;
}

.tableContainer .tableEditBtn {
    margin-right: 10px;
    background-image: url("../../images/pencil.svg");
}

.tableContainer .tableDeleteBtn {
    margin-right: 10px;
    background-image: url("../../images/trash.svg");
}

.tableContainer .tableMenuBtn {
    background-image: url("../../images/Menu.svg");
}


  .modalActions {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 75px; 
    width: 111px;
    z-index: 500;
    justify-content: center;
    color: #000;
  }
  
  .modalActionItem {
    cursor: pointer;
    height:40%; 
    display: flex; 
    font-size: 14px;
    font-family: 'Geomanist';
    color: #000000;
    text-align: left;
    align-items: center;
    padding-left: 10%;
  }

  .modalActionItem:hover {
    background-color: #f4f4f4;
  }
  
  .dropdownmenuopen {
    display: block;
    position: absolute;
    right: 35%; 
    background-color:  #FFFFFF;
    border-radius: 7px;
    padding: 8px;
    z-index: 500;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 0.5px 5px rgba(0, 0, 0, 0.12); 
  }

  .dropdownmenuopen:after {
        content: ''; 
        border-top: 1px solid #D0D0D0;
        border-right: 1px solid #D0D0D0;
        position: absolute;
        right: 16px;
        top: -8px;
        width: 15px;
        height: 15px;
        transform: rotate(-45deg);
        background-color: #FFFFFF;
  }

  
  .searchBox {
    font-family: Geomanist;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
}

.searchBoxKeywordInput {
    height: 40px;
    width: 40%;
    padding-left: 37px !important;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 5px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
    font-size: 15px !important;
    background-size: 24px;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.searchBoxForm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.tableNoPadding {
    padding: 0 !important; 
    background-color: white !important;
    height: 100%;
}

.selectedTimePeriod {
    padding: 6.5px 8.25px 6.5px 9px; 
    box-shadow: 0px 3px 0.75px 0px rgba(0, 0, 0, 0.04), 0px 2.25px 6px 0px rgba(0, 0, 0, 0.12); 
    background: #FFF; 
    border-radius: 5.25px; 
    font-weight: 700;
}

.notSelectedTimePeriod {
    color: #000;
    font-weight: 400; 
    
}