.container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* horizontal */
  align-items: center;     /* vertical */
  width: 100%;
}

.result {
  margin-top: 100px;
  word-wrap: break-word;
  width: 300px;
}

.qrImage section div {
  box-shadow: unset !important;  
  position: absolute !important; 
  top: 222px !important;
  left:auto !important; 
  height: 251px !important;  
  width: 251px !important;
}

.qrImage section { 
  padding-top: 0px !important; 
  height: 100%   ; 
  display: flex;   
  justify-content: center;   
  align-items: center;   
}


.animationBox {
  background-image: linear-gradient(180deg, rgba(229, 250, 246, 0.00) 0%, rgba(229, 250, 246, 0.60) 100%);
  height: 0px; 
  width: 250px; 
  border-bottom: 2px solid var(--tiffany-100, #E8E9FD);
  z-index: 2; 
  position: absolute;
  animation: animateLine 2s linear infinite; 
  top: 222px;
}


@keyframes animateLine {
  0% {
    height: 0
  }

  100% {
    height:  250px;
  }
}