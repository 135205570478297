#delete-button-container {
 
  
    box-sizing: border-box;
    border: 1.68204px solid red;
    background: #FFFFFF;
    border-radius: 4.20511px;
    /* padding: 10px;
    gap: 8.41px; */
  }
  
#delete-button-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
  
    text-align: center;
  
    color:red;
  ;
  }
  
  #duplicate-button-container {
 
  
    box-sizing: border-box;
    border: 1.68204px solid #0277AD;
    background: #FFFFFF;
    border-radius: 4.20511px;
    /* padding: 10px;
    gap: 8.41px; */
  }
  
  #duplicate-button-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
  
    text-align: center;
  
    color:#0277AD;
  ;
  }
  