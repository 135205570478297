.shadow {
    width: 92%;
    margin-top: 3.5rem;
}

.container {
    width: 62%;
    margin-top: calc(2vw);
    margin-bottom: calc(3vw);
    padding: 4vw 0 2vw 0;
}

.centerLayout {
    display: flex;
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap;
}

.dashBoardContainer {
    height: 100%;
    min-height: 700px;
    display: flex;
    justify-content: space-between;
    background-color: #FCFBFF;
    padding: 3% 3%;
    overflow-y: hidden;
}

.dashBoardContainerMobile {
    height: calc(100vh - 75px);
    background-color: #FCFBFF;
    padding: 3% 3%;
    overflow-y: auto;
}

@media only screen and (min-width: 250px) and (max-width: 800px) {
    .container {
        width: 85vw !important;
    }
    .illustration {
        height: 48vw !important;
        width: 80vw !important;
    }

}

@media only screen and (max-height: 800px) {
    .shadow {
        margin-bottom: 3rem !important;
    }
}

.illustration {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: rgba(0,0,0,0);
    height: 14rem;
    padding: 0;
    width: 23rem;
    border-bottom: 0;
    background-image: url('./images/course dashboard illustration.svg');
}

[class*='panelSpin'] [class*='ant-spin-container'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.panelBody {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    overflow-y: auto;
}

.panelBodyJoinPoll {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
}

.joinPollButton {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.035em;
    margin: auto;
    padding: 8px;
}

.courseTitle {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.035em;
    color: #575757;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
}

.coursesBody {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    padding: 25px;
    overflow-y: auto;
}

.coursesBody [class*='table-responsive'] {
    height: 100%
}

.noRecentResultsTitle {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.035em;
    color: #000000;
    text-align: center;
    margin-top: 20px;
}

.gradientText {
    background-image: -webkit-linear-gradient(154.85deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: right;
    min-width: 8ch;
}

.recentResultsTitle {
    padding-top: 61px;
    margin-left: 41px;
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    color: #000000;
}

.recentResultsSubTitle {
    margin-top: 10px;
    margin-left: 41px;
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #515151;
}

.resultsBody{
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    overflow-y: auto;
}

.resultsBody [class*='table-responsive'] {
    height: 100%
}

.pollCodeInput {
    text-align: center;
    font-size: 20px;
    box-sizing: border-box;
    width: 14%;
    background: #FFFFFF;
    border: 1.3px solid #939393;
    border-radius: 4px;
}

.pollInputContainer {
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin-top: 13px;
    margin-bottom: 13px;
    max-height: 55px;
    gap: 5%;
}

.joinPollIcon {
    margin-right: 20px;
}

@media only screen and (max-width: 1450px) {
    .joinPollIcon {
        margin-right: 10px;
        width: 150px;
    }
}

@media only screen and (max-width: 1300px) {
    .joinPollIcon {
        margin-right: 10px;
        width: 120px;
    }
}

@media only screen and (max-width: 1200px) {
    .joinPollIcon {
        margin-right: 0px;
        width: 0px;
    }
}

@media only screen and (min-height: 830px) {
    .pollInputContainer {
        height: 65px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.viewResultsButton {
    padding: 5px;
    border-image-source: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%) !important;
    border-radius: 20px;
    border: 2px solid;
    border-image-slice: 1;
    background-origin: border-box;
    background-clip: content-box, border-box;
}
