div.DraftEditor-root {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: left !important;
    color: #6c6c6c !important;
    outline:none !important;
    border: none !important;
    box-shadow: none !important;
    font-family: "Poppins";
    font-style: normal;
    padding: 0;
  }

.DraftEditor-editorContainer {
  height: 145px !important;
  overflow-y: auto !important;
}

.custom-editor-container .public-DraftEditorPlaceholder-root {
  color: #535353;
  text-align: center;
  font-family: "Geomanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



.FITBAnswerContainer {
  height: 30vh; 
  overflow-y: auto;
}

.clickable-image-container {
  position: relative;
  text-align: end;
  justify-content: end;
  max-width: 90%;
  max-height: 80%;
  flex: 1;
}

@media only screen and (max-height: 950px) {
  
  .FITBAnswerContainer {
    height: 25vh; 
  }

}


@media only screen and (max-height: 850px) {
  
  .FITBAnswerContainer {
    height: 15vh; 
  }

}

@media only screen and (max-height: 765px) {
  
  .FITBAnswerContainer {
    height: auto; 
    overflow-y: visible;
  }

  .FITB-main-container {
    max-height: 80%; 
    overflow-y: auto;
  }

}

@media only screen and (max-height: 650px) {

  .FITB-main-container {
    max-height:60%; 
  }

  .clickable-image-container {
    max-height: 80%;
  }

}