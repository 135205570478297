.container {
    display: flex;
    padding: 30px 5% 30px 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    background-color: #FCFBFF;
    min-height: 100%;
    overflow-y: auto;
}

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.titleBox {
    font-family: Poppins;
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/manage_users_color.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;

}

.searchBox {
    font-family: Geomanist;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 80px;
    margin: 30px 0;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
}

.searchBoxForm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.searchBoxKeywordInput {
    height: 45px;
    width: 40%;
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus,
.searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
        width: 100%;
    }
    
    .tableContainer table {
        font-family: 'Geomanist';
        table-layout: fixed;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: white;
    }
    
    .tableContainer table th {
        padding: 20px;
    }
    
    .tableContainer tr>th {
        background-color: transparent !important;
    }

    .tableContainer tr>td {
        word-break: break-word;
    }
    
    .tableContainer tr {
        border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    }
    
    .tableContainer table thead,
    tbody,
    tfoot {
        border-top: 0 !important;
    }
    
    .tableContainer table>thead>tr>th {
        font-weight: 600 !important;
    }
    
    .tableContainer .tableBtnUnit {
        line-height: 0 !important;
        padding: 0 !important;
    }
    
    .tableContainer .tableBtn {
        display: inline-block;
        width: 25px;
        height: 25px;
    }
    
    .tableContainer .tableBtn:hover {
        cursor: pointer;
    }

    .tableContainer .tableMenuBtn {
        background-image: url("../../images/Menu.svg");
    }
    
    .tableContainer .tableEditBtn {
        margin-right: 10px;
        background-image: url("../../images/pencil.svg");
    }
    
    .tableContainer .tableDeleteBtn {
        background-image: url("../../images/trash.svg");
    }
    
    .tableContainer .tablePublishBtn {
        display: inline-block;
        border-radius: 0.25rem;
        padding: 0.625rem 0.75rem;
        border: 0.125rem solid rgba(10, 130, 14, 1);
        color: rgba(10, 130, 14, 1);
    }
    
    .tableContainer .tablePublishBtn:hover {
        cursor: pointer;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(10, 130, 14, 1);
    }
    
    /*customize bootstrap pagination*/
    .tableContainer [class*='page-item'][class*='active'] {
        border: none;
    }
    
    .tableContainer [class*='page-item'][class*='active'] [class='page-link'] {
        background-color: rgba(0, 107, 155, 1);
        color: rgba(255, 255, 255, 1);
        border-color: rgba(0, 107, 155, 1);
    
    }
    
    .tableContainer [class*='page-link'] {
        color: rgba(0, 107, 155, 1);
    }
    
    .tableContainer [class*='page-link']:hover {
        color: rgba(0, 107, 155, 1);
    }

.tableContainer [class*="form-check-input"] {
    width: 24px;
    height: 24px;
    border-radius: 3px;
}

.tableContainer [class*="form-check-input"]:checked, .tableContainer [class*="form-check-input"]:indeterminate {
    background-color: #126FC7;
}
    
    .createUserButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        margin-left: 35px;
        margin-top: 15px;
        width: 170px;
        height: 50px;

        background: #FFFFFF;
        border: 1.73px solid #0277AD;
        border-radius: 4.68287px;
        /* background: url("../../images/plus\ 1.svg") no-repeat left; */

        font-family: 'Geomanist';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.035em;

        color: #0277AD;
        white-space: nowrap;
    }

    .createUserButton:hover {
        cursor: pointer;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(0, 107, 155, 1);
    }

    .deleteSelectedBtn {
        margin: 0 0 20px 87%;
        white-space: nowrap;
    }
    /*=================responsive====================*/
    @media only screen and (min-width: 250px) and (max-width: 950px) {
        .searchBoxKeywordInput {
            margin-left: 5px;
            padding-left: 27px;
            background: url("../../images/zoom.svg") no-repeat left;
            background-position-x: 5px;
            border: 0.066rem solid rgba(208, 208, 208, 1) !important;
        }
    
        .searchBoxDateInput {
            width: 80px;
            min-width: 50px;
        }
    
        .searchBoxSearchBtn {
            margin-right: 5px;
            padding: 0.5rem 0.6rem;
            border: 0.125rem solid rgba(0, 107, 155, 1);
            color: rgba(0, 107, 155, 1);
            font-size: 0.625rem;
            line-height: 0.625rem;
        }
    
        .tableContainer table {
            font-size: 1rem;
        }
    }
    
    .createButton {
        border-radius: 0.211rem;
        padding: 1.07rem 2.188rem 1.07rem 2.188rem;
        border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
        height: 15%;
    }
    
    .createButton:hover {
        background-color: rgba(2, 119, 173, 1);
        color: rgba(255, 255, 255, 1);
    }
    
    .center {
        margin: 5%;
        justify-content: center;
        text-align: center;
    }
    
    .text {
        justify-content: center;
        margin: 5%;
        text-align: center;
}

    .checkboxCell {
        /* display: flex !important; */
        padding: 0 !important;
        /* justify-content: center; */
        vertical-align: middle;
    }

    .otherHeaderCell {
        padding: 20px !important;
    }

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
}


.skeletonHeaderContainer {
    display: grid;
    grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
    height: 70.4px;
    padding: 0.031rem;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 16px;
    font-family: 'Geomanist';
    font-weight: 600;
}

.skeletonBodyContainer {
    margin: 0px !important;
    display: grid;
    grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
}

.skeletonBodyCell {
    display: flex !important;
    margin: 15px auto 0px;
}
