.background-img {
  background-image: url("./images/rainbow.svg");
  position: fixed;
  text-align: center;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.mobile-login-logo {
  background-size: cover;
  height: 75px;
  width: 75px;
}

.mobile-login-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
  padding: 0%;
}

.mobile-login-subtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #1D1D1D;
}

@media only screen and (max-width: 950px) {
  .joinPollBtn {
    border: none;
    padding: 10px 15px !important;
    background: #ffd786;
    border-radius: 3px;
    color: #000000;
    font-weight: 600;
    width: 90%;
    height: 66px;
    border: 0.87px solid #F1B843;
    border-radius: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19.026px;
    line-height: 22px;
    margin: 2%;
  }
}

@media only screen and (min-width: 950px) {
  .joinPollBtn {
    border: none;
    padding: 10px 15px !important;
    background: #ffd786;
    border-radius: 3px;
    color: #000000;
    font-weight: 600;
    width: 350px;
    height: 66px;
    border: 0.87px solid #F1B843;
    border-radius: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19.026px;
    line-height: 22px;
    margin: 2%;
  }
}

.mobile-login-issues {
  color: #464646;
  font-family: Geomanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0% 0% 7.5% 0%;
}

.mobile-login-footer {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #464646;

}

.btn-holder {
  margin: 2%;
  width: 100%;
}

.center-all {
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  position: absolute;
  top: 15px;
  min-height: 500px;
}