:root {
    --yuja-primary-color: #42296E;
    --yuja-primary-disabled-color: #54476B;
    --yuja-primary-disabled-bg-color: #C2BBCF;
    --yuja-secondary-color: #0B6F85;
    --yuja-secondary-disabled-color: #305963;
    --yuja-secondary-disabled-bg-color: #B0CBD1;
    --yuja-delete-color: #BF0033;
    --yuja-delete-disabled-color: #BF0033;
    --yuja-delete-disabled-bg-color: #E8AEBE;
}

.container {
    padding: 8px 10px;
    width: fit-content;
    height: fit-content;
    font-family: Geomanist;
    font-size: max(0.8vw, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .wrapper svg {
        display: none;
    }
}


.primary {
    color: var(--yuja-primary-color);
    border: 2px solid var(--yuja-primary-color);
    background: #FFF;
}

.primary path {
    stroke: var(--yuja-primary-color);
}

.primary[disabled] {
    background: var(--yuja-primary-disabled-bg-color);
    border: 2px solid var(--yuja-primary-disabled-bg-color);
    color: var(--yuja-primary-disabled-color);
}

.primary[disabled] path {
    stroke: var(--yuja-primary-disabled-color);
}

.primary:focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-primary-color);
}

.primary:focus path{
    stroke: #FFF;
}

.primary:hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-primary-color);
}

.primary:hover path{
    stroke: #FFF;
}

.primary[disabled]:hover {
    cursor: not-allowed;
    color: var(--yuja-primary-disabled-color);
    background: var(--yuja-primary-disabled-bg-color);
}

.primary[disabled]:hover path{
    stroke: var(--yuja-primary-disabled-color);
}

.secondary {
    color: var(--yuja-secondary-color);
    border: 2px solid var(--yuja-secondary-color);
    background: #FFF;
}

.secondary path {
    stroke: var(--yuja-secondary-color);
}

.secondary[disabled] {
    background: var(--yuja-secondary-disabled-bg-color);
    border: 2px solid var(--yuja-secondary-disabled-bg-color);
    color: var(--yuja-secondary-disabled-color);
}

.secondary[disabled] path {
    stroke: var(--yuja-secondary-disabled-color);
}

.secondary:focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-secondary-color);
}

.secondary:focus path{
    stroke: #FFF;
}

.secondary:hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-secondary-color);
}

.secondary:hover path{
    stroke: #FFF;
}

.secondary[disabled]:hover {
    cursor: not-allowed;
    background: var(--yuja-secondary-disabled-bg-color);
    color: var(--yuja-secondary-disabled-color);
}

.secondary[disabled]:hover path {
    stroke: var(--yuja-secondary-disabled-color);
}

.delete {
    color: var(--yuja-delete-color);
    border: 2px solid var(--yuja-delete-color);
    background: #FFF;
}

.delete path {
    stroke: var(--yuja-delete-color);
}

.delete[disabled] {
    background: var(--yuja-delete-disabled-bg-color);
    border: 2px solid var(--yuja-delete-disabled-bg-color);
    color: var(--yuja-delete-disabled-color);
}

.delete[disabled] path {
    stroke: var(--yuja-delete-disabled-color);
}

.delete:focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-delete-color);
}

.delete:focus path{
    stroke: #FFF;
}

.delete:hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-delete-color);
}

.delete:hover path{
    stroke: #FFF;
}

.delete[disabled]:hover {
    cursor: not-allowed;
    background: var(--yuja-delete-disabled-bg-color);
    color: var(--yuja-delete-disabled-color);
}

.delete[disabled]:hover path {
    stroke: var(--yuja-delete-disabled-color);
}