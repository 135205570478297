.question-main-container {
  position: relative !important;
}

.question-row {
  margin-top: 1.25rem !important;
  margin-bottom: 0.875rem !important;
}

.question-header-col {
  flex: 0 0 auto !important;
  width: 58.33333333% !important;
}

.question-input-col {
  flex: 0 0 auto !important;
  width: 76% !important;
}

@media only screen and (max-width: 1650px) {
  .question-input-col {
    width: 71% !important;
  }

  .question-header-col {
    width: 40.33333333% !important;
  }

  /* .dropdownText {
    font-size: 11px !important;
  } */
}

.img-container-ci-poll {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 950px) {
  .img-container-ci-poll {
    max-width: 250px;
    max-height: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .question-input-col {
    width: 71% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .question-input-col {
    width: 65% !important;
  }
}

.fitb-row {
  margin-top: 0.3rem !important;
  margin-bottom: 1.25rem !important;
}

.fitb-blank {
  color: #029ce2;
  font-weight: 700;
  font-family: "Poppins";
  font-style: normal;
}

.radio-button-column {
  flex: 0 0 auto !important;
  width: 9.733333% !important;
}

.fitb-add-answer-col {
  flex: 0 0 auto !important;
  width: 5.33333333% !important;
}

.TF-radio-button-column {
  flex: 0 0 auto !important;
  width: 15% !important;
  margin-right: 0px !important;
  margin-left: 0.879rem !important;
}

.TF-delete-icon {
  flex: 0 0 auto !important;
  width: 4.333333% !important;
}

.drag-icon-column {
  flex: 0 0 auto !important;
  width: 10px !important;
  margin-right: 10px; 
}

.cont-radio {
  flex: 0.2 !important;
}

.cont-radio-survey {
  flex: 0 !important;
  padding-left: 2px !important;
}

.questions-page-icons {
  width: 2.25rem;
}

input[type="file"] {
  display: none;
}

.fitb-containers {
  min-height: 9.188rem;
  background: #ffffff;
}

.fitb-titles {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #000000;
  margin-left: 9px !important;
  padding: 0px !important;
}

.fitb-input-col {
  flex: 0 0 auto !important;
  width: 100% !important;
  padding: 0px !important;
}

@media only screen and (max-width: 950px) {
  .fitb-input-col {
    width: 100% !important;
  }
}

.add-blank-col {
  padding: 0px !important;
}

@media only screen and (max-width: 950px) {
  .add-blank-col {
    width: 100% !important;
    margin-right: 10px;
  }
}

.fitb-bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  margin: 5px;
  color: #6c6c6c;
}

.fitb-blank-col {
  flex: 0 0 auto !important;
  width: 10.66666667% !important;
}

.ccontainer {
  padding-top: 1rem;
}

.text-required {
  color: red !important;
}

.btn-primary {
  color: #fff;
  background-color: rgb(33, 89, 179) !important;
  border-color: rgb(33, 89, 179) !important;
}
.margin-right {
  margin-right: 20px;
}

.image-margin {
  margin-bottom: 5px;
  margin-left: 3.75px;
}

.dropdown-image-margin {
  margin-bottom: 2px;
  margin-left: 3.75px;
}

.dropdown-menu {
  width: 100% !important;
}

/* .poll-navdropdown-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px;
  line-height: 20px;
  color: #000000 !important;
  background: #fefefe;
  box-sizing: border-box;
  border-radius: 0px;
  width: 100%;
} */

.question-type-title {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: left;
  margin-left: 10px !important;
  color: #000;
}

.crow-e {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80% !important;
}

.margin-bottom {
  margin-bottom: 20px;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.customized-form {
  /* width: 90%; */
  /* margin: auto; */
}

.accessibilityIcon{
  padding-left: 10px;
}

.image-upload-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 62px;
  background: #F7EFF6;
  border: 2px dashed #42296E;
  border-radius: 4.124px;
  margin-bottom: 15px;
}

.upload-icon {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 50.5px;
}

.drag-drop-text {
  display: inline;
  width: 171px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #444444;
  margin: 0;
}

.or-text {
  display: inline;
  margin-left: 78px;
  width: 15px;
  height: 10px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 15.4754px;
  line-height: 15px;
  color: #444444;
}

.browse-box {
  box-sizing: border-box;
  margin-left: 55.5px;
  margin-top: 10px;
  padding: 10px;
  gap: 8.41px;
  width: 60px;
  height: 32px;
  background: #FFFFFF;
  border: 1.68204px solid #0277AD;
  border-radius: 4.20511px;
}

.browse-text {
  display: inline;
  width: 40px;
  height: 12px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 250;
  font-size: 12px;
  line-height: 12px;
  color: #0277AD;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.image-preview-container {
  text-align: end;
  justify-content: center;
  margin-bottom: 25px;
}

.preview {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.MH-response {
  width: 100% !important; 
}

.Create-quiz-question-type {
  border-radius: 39.828px;
  border: 1.069px solid #42296E;
  width: 165.243px;
  height: 38.133px;
  padding: 6.355px; 
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(239deg, rgba(242, 140, 185, 0.08) -23.35%, rgba(239, 137, 184, 0.08) -21.87%, rgba(186, 84, 161, 0.08) 2.25%, rgba(153, 51, 147, 0.08) 20.37%, rgba(141, 39, 142, 0.08) 30.32%, rgba(123, 41, 143, 0.08) 44.92%, rgba(77, 46, 144, 0.08) 74.01%, rgba(46, 49, 145, 0.08) 91.51%);
}

.CreateQuestionTypeContainer {
  border-radius: 4.205px;
  border: 2px solid #42296E;
  height: 33px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.41px; 
}

.droppable-multiple-choice-question-options {
  overflow-y: auto; 
  height: 380px; 
  overflow-x: hidden
}

@media only screen and (max-height: 850px){
  .droppable-multiple-choice-question-options {
    height: 30vh; 
  }

  .create-question-body {
    height: 70vh !important;
  }
}

@media only screen and (max-height: 735px){
  .droppable-multiple-choice-question-options {
    height: 20vh; 
  }

  .create-question-body {
    height: 65vh !important;
  }
}

@media only screen and (max-height: 600px){
  .droppable-multiple-choice-question-options {
    height: 15vh;
  }

  .create-question-body {
    height: 60vh !important;
  }
}

@media only screen and (max-height: 480px){
  .droppable-multiple-choice-question-options {
    height: 10vh;
  }

  .create-question-body {
    height: 50vh !important;
  }
}