.bigButtonFont {
    text-align: center;
    font-weight: 600;
    font-size: 0.883rem;
    line-height: 1.313rem;
    margin: 1.563rem 1.688rem;
}

.bigButton {
    /*width: 10.901rem !important;*/
    border-radius: 0.211rem;
    padding: 1.07rem 2.188rem 1.07rem 2.188rem;
    border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
}

.midButtonFont {
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin: 1.563rem 1.688rem;
}

.midButton {
    /*width: 10.901rem !important;*/
    border-radius: 0.177rem;
    padding: 0.859rem 1.77rem 0.859rem 1.77rem;
    border: 0.203rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
}

.normalButton:hover {
    background-color: rgba(2, 119, 173, 1);
    color: rgba(255, 255, 255, 1);
}

.disabledButton {
    background: rgba(227, 227, 227, 1)!important;
    border: 0.203rem solid rgba(144, 144, 144, 1)!important;
    color: rgba(144, 144, 144, 1)!important;
}

.disabledButton:hover {
    cursor: not-allowed;
}

.gradientWrap {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4.5px;
    background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
}

.gradientBtn {
    border-radius: 2px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    color: #000000;
    font-weight: 400;
    font-size: 13px;
    font-family: Geomanist, serif;
    background-color: #FFFFFF;
    margin: 2px;
    padding: 6px 10px;
    cursor: pointer;
}


.gradientBtn:hover {
    background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
    color: #FFFFFF;
}

.gradientBtn:focus {
    background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
    color: #FFFFFF;
}


