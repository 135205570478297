.modal-content {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px !important;
}

#support-modal .modal-content{
  border: 1.10246px solid rgba(196, 196, 196, 0.4) !important;
  box-sizing: border-box;
}

#form-modal .modal-content {
  background: #ffffff;
  border: 1.10246px solid rgba(196, 196, 196, 0.4) !important;
  box-sizing: border-box;
  box-shadow: 2.20491px 2.20491px 12.127px 1.10246px rgba(0, 0, 0, 0.05);
}

.form-subtext {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 13px;

  color: #000000 !important;
  margin: 0 0 20px 0;
}

.preview-form-subtext {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 14px;
  word-break: break-all;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px; 

  color: #000000 !important;
  margin: 0 0 20px 0;
}

.modal-close {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  border-radius: 5px !important;
  border-width: 0px;
}

.modal-close:hover {
  background-color: #d7d7d7;
}

.form-label {
  margin: 15px 0px;
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: normal;
  color: #1E1E1E;
  margin-bottom: 8px !important;
}

.form-labelReg {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: normal;
  color: #1E1E1E;
}

.form-labelRegTitle {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: normal;
  color: #1E1E1E;
  margin-bottom: 12px !important;
}

.form-control {
  margin: 2px 0px;
  background: #ffffff;
  border: 1px solid #595959 !important;
  box-sizing: border-box;
  border-radius: 2.72459px !important;

  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13.2295px !important;
}

.invalid-feedback {
  margin: 0px !important;
  border: 0px !important;
  font-size: 0.75rem !important;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.modal-header {
  height: 75px !important;
  border-bottom: 6px solid !important;
  /* border-image: url(../../images/modal_bar.svg) 10 !important; */
  border-image: url(../../images/modal_bar_new.svg) 10 !important;
}

.modal-body {
  padding: 25px !important;
}

.modal-footer {
  padding: 13px 20px 19px 0px !important;
  border-top: 0.5px solid #cfcece !important;
}

.modal-button-left {
  background: #ffffff;
  border: 1.68204px solid #0277ad;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  margin: 0 !important;
  padding: 8px 10px !important;
  color: #0277ad;
  position: absolute;
  left: 3.5%;
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.modal-button-left:hover {
  background: #0277ad;
  color: #ffffff;
}

.modal-button-submit {
  background: #ffffff;
  border: 1.68204px solid #0277ad;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  margin: 0 0 0 8px !important;
  padding: 8px 10px !important;
  color: #0277ad;

  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.modal-button-submit:hover {
  background: #0277ad;
  color: #ffffff;
}

.modal-button-submit:focus {
  background: #0277ad;
  color: #ffffff;
}

.modal-button-cancel {
  background: #ffffff;
  border: 1.68204px solid #454242;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  margin: 0px 8px !important;
  padding: 8px 10px !important;
  color: #454242;

  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.modal-button-cancel:hover {
  background: #454242;
  color: #ffffff;
}

.modal-button-cancel:focus {
  background: #454242;
  color: #ffffff;
}

.warning-button-cancel {
  background: #ffffff;
  border: 1.68204px solid green;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  margin: 0 16px 0 0 !important;
  padding: 8px 10px !important;
  color: green;

  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.warning-button-cancel:hover {
  background: green;
  color: #ffffff;
}

.warning-button-cancel:focus {
  background: green;
  color: #ffffff;
}

.warning-button-submit {
  background: #ffffff;
  border: 1.68204px solid red;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  margin: 0px !important;
  padding: 8px 10px !important;
  color: red;

  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.warning-button-submit:hover {
  background: red;
  color: #ffffff;
}

/* .modal-dialog {
  margin-top: 5% !important;
} */

.modal-title {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  color: #38225E;
}

.warning-title {
  font-family: "Geomanist";
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  color: #38225E;
}

.publish-modal-close-button {
  float: right;
  width: 1.563rem;
  margin-top: 6.69px;
  border-radius: 5px;
}

.publish-modal-close-button:hover {
  background-color: #d7d7d7; 
}

.qr-element {
  align-content: center;
  padding: 0%;
  margin: 0%;
}

.question-modal {
  padding: 0%;
  margin: 0%;
  min-width: min(50vw, 800px);
}

.sa-modal {
  padding: 0%;
  margin: 0%;
  /* min-width: 750px; */
}

.sa-grade-modal {
  min-width: min(50vw, 890px);
}

.qp-modal {
  min-width: 37vw; 
}

@media only screen and (max-width: 480px) {
  .FITB-modal {
    height: 100%; 
    width: 100%; 
    margin: 0px;
  }

  .FITB-modal .modal-content {
    height: 100%; 
    border: 0px solid #000 !important;
  }

  .FITB-modal .modal-header {
    border-bottom: 0px solid #000 !important;
    background-color: #F1EFF7 ;
  }

  .FITB-modal .modal-body {
    background-color: #F1EFF7 ;
  }
  .FITB-modal .modal-title {
    font-size: 18px !important;
  }
}

.ed-modal {
  min-width: 30vw;
}

.ac-modal {
  min-width: 564px;
}

.warning-modal {
  height: 100vh;
  display: flex;
  align-items: center;
}

.activatePollText {
  font-family: "Geomanist";
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  color: #000000;
  margin: 10px 0px;
  letter-spacing: 0.035rem;
}

.activatePollError {
  font-family: "Geomanist";
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  color: red;
  margin: 10px 0px;
  letter-spacing: 0.035rem;
}

.custom-box2 {
  height: 45px !important;
  text-align: center;
  margin-left: auto !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  padding: 2px;
}

.custom-input2 {
  width: 30px !important;
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
}

.course-modal-input:checked:before {
  background: #ffffff !important;
  height: auto !important;
}

.course-modal-input:checked {
  background-color: #0277ad !important;
  border-color: #0277ad !important;
}

.poll-time-limit-input {
  border: solid 1px hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  height: 38px !important;
}

.question-modal2 {
  /* width: 100%; */
  /* height: 100%; */
  padding: 0%;
  margin: 0%;
  min-width: 750px;
}

@media only screen and (max-width: 950px) {
  .question-modal2 {
    min-width: 350px !important;
  }
}

@media only screen and (max-width: 350px) {
  .question-modal2 {
    min-width: 200px !important;
  }
}

.custom-caption2 {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  padding-left: 11px;
}

.gradebookOptionsRow {

}

.gradebookOptionTitle {

}

.gradebookOptionConfigure {
  
}

.attemptsOption {
  /* display: flex; */
  /* height: 35px; */
  padding: 0.5vh 0px;
}

.attemptsOptionText {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  height: 13px;
  color: #000000;
}

.attemptsCheckbox {
  display: inline-flex;
  /* width: 50px !important; */
  /* margin-right: 10px !important; */
  width: 15px;
  height: 15px;
  margin-left: 3px;
}

.attemptsOptionText {
  display: inline-flex;

}

.attemptsInputBox {
  display: inline-flex;
  width: 40px;
  margin-right: 10px;
  word-break: break-all;
  border: 1px solid #000000;
  text-align: center;
  /* border-radius: 0.25rem; */
  /* background: #0277AD; */
  /* color: #FFFFFF; */
}

.indeterminate-checkbox {
  /* position: relative !important; */
  z-index: 99 !important;
}

.indeterminate-checkbox:hover {
  cursor: pointer;
}

.QuestionProfile-value{
  color: rgb(2, 119, 173);  
  cursor: pointer; 
  padding: 10px 20px; 
}

.QuestionProfile-value-selected{
  color: rgb(2, 119, 173);  
  cursor: pointer; 
  padding: 10px 20px; 
  background-color: #f4f4f4;
}

.QuestionProfile-value-selected:hover{
  text-decoration: underline;
}



.QuestionProfile-value:hover{
  text-decoration: underline;
}

.QuestionProfile-link {
  color: #0277ad; 
  cursor: pointer;
}

.QuestionProfile-link:hover {
  text-decoration: underline;
}

.questionProfile-cards {
  width: 175px;
  height: calc(175px * 2 / 3);
  border: 2px solid rgb(2, 119, 173);
  padding: 5px; 
  margin-bottom: 16px ;
  background-color: #fff; 
  display: flex; 
  font-size: 20px;
  color: rgb(2, 119, 173);
  font-family: Geomanist;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10.8333px;
  cursor: pointer;
}

.questionProfile-cards:hover {
  background-color: #e6f2f7;
}

.questionProfile-cards-activated {
  width: 175px;
  height: calc(175px * 2 / 3);
  border: 2px solid rgb(2, 119, 173);
  padding: 5px; 
  margin-bottom: 16px ;
  background-color: rgb(2, 119, 173); 
  display: flex; 
  font-size: 20px;
  color: #fff;
  font-family: Geomanist;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 10.8333px;
  cursor: pointer;
}

@media only screen and (max-width:  485px) {
  .questionProfile-cards {
    width: 135px;
  }

  .questionProfile-cards-activated {
    width: 135px
  }
}

.questionProfile-layout{
  display: grid; 
  grid-template-columns: 21.25% 21.25% 21.25% 21.25%; 
  grid-template-rows: 21.25% 21.25% 21.25% 21.25%; 
  overflow-y: auto; 
  height: 70vh; 
  gap: 5%; 
  margin: 5% 5% 0 5%; 
}

@media only screen and (max-width: 1900px) {
  .questionProfile-layout{
    grid-template-columns: 28.3% 28.3% 28.3%; 
  }
}

@media only screen and (max-height:  650px) {
  .questionProfile-layout{
    grid-template-rows: 28.3% 28.3% 28.3%; 
  }
}

@media only screen and (max-height:  540px) {
  .questionProfile-layout{
    grid-template-rows: 42.5% 42.5%; 
  }
}

@media only screen and (max-height:  350px) {
  .questionProfile-layout{
    grid-template-rows: 85%; 
  }
}

@media only screen and (max-width:  1300px) {
  .questionProfile-layout{
    grid-template-columns: 42.5% 42.5%; 
  }
}

@media only screen and (max-width:  485px) {
  .questionProfile-layout{
    grid-template-columns: 85%; 
  }
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2){
  z-index: 1100;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4){
  z-index: 1050;
}

.react-datepicker-wrapper {
  display: none;
}


.react-datepicker__input-container {
  display: none;
}

.react-datepicker__input-container input {
  position: relative !important;
  min-width: 225px !important;
  height: 36px !important;
  background: #fff;
  border: 1px solid #ced0da !important;
  border-radius: 3px !important;  
  font-size: 13px;
  color: #232020;
  font-weight: 400;
  padding: 10px;
  font-family: Geomanist;
  cursor: pointer;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #E7E7E7 !important;
}

.react-datepicker__day--in-range {
  background: #42296E !important;
}

.react-datepicker__current-month {
  font-family: "Geomanist" !important;
  font-weight: 700 !important;
  font-size: 13.5px !important;
  color: var(--Dark, #232020) !important; 
  line-height: 19.5px;
  align-self: center;
  font-variant-numeric: lining-nums tabular-nums;
}

.react-datepicker__day-name {
  color: #8E8C9A !important;
  font-family: "Geomanist" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  width: 36px !important; 
  height: 36px !important;
  margin: 0px !important;
}

.react-datepicker__header {
  background-color: #FFFFFF !important;
  border-bottom: none !important;
  padding-top: 0px !important; 
}


.react-datepicker {
  border-radius: 12px !important;
  width: 312px !important; 
  height: fit-content !important;
  Padding: 15px 30px 4px 30px !important; 
  box-shadow: 0px 0px 6px 0px #2320201A !important;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month {
  margin: 0px !important; 
}

.react-datepicker__day {
  width: 36px !important; 
  height: 36px !important;
  line-height: 36px !important;
  margin: 0 !important;
  color: #232020 !important; 
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Geomanist" !important;
  text-align: center !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.react-datepicker__navigation--next {
  margin-top: 15px;
  margin-right: 10px;
}

.react-datepicker__navigation--previous {
  margin-top: 15px;
  margin-left: 10px;
}

.react-datepicker__day-names {
  margin-top: 18px;
  margin-bottom: 0px !important;
}

.react-datepicker__header {
  padding: 0px; 
}

.react-datepicker__day--selected {
  background: #42296E !important;
  color: #ffffff !important; 
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper {
  padding-top: 5px !important;
}

.react-datepicker-time__caption {
  display: none !important;
}

.react-datepicker__input-time-container {
  margin-left: 0 !important;
  margin-top: 0px !important;
}

.react-datepicker-time__input {
  margin-left: 0 !important;
}

.join-poll-modal-footer {
  padding: 10px 20px!important;
}