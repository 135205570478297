.smallBox {
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
    width: 400px;
    margin-top: 20px;
    height: fit-content;
}


@media only screen and (min-width: 1850px) {
    .smallBox {
        width: 500px;
    }
}

@media only screen and (max-width: 1849px) {
    .smallBox {
        width: 450px;
    }
}

@media only screen and (max-width: 1700px) {
    .smallBox {
        width: 420px;
    }
}

@media only screen and (max-width: 1550px) {
    .smallBox {
        width: 350px;
    }
}

@media only screen and (max-width: 1350px) {
    .smallBox {
        width: 315px;
    }
}

@media only screen and (max-width: 1250px) {
    .smallBox {
        width: 275px;
    }
}

@media only screen and (max-width: 1010px) {
    .smallBox {
        width: 200px;
    }
}

.cardHeader {
    margin-left: 0.5em;
    color: #000;
    font-family: Geomanist;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
}
