.fullWidthClass {
	width: -webkit-fill-available;
    width: -moz-available;
}

.mainDiv {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.container {
    display: flex;
    padding: 30px 5% 30px 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
}

.titleBox {
    border-radius: 6.68px 6.68px 0px 0px;
    border: 0.903px solid #EFEFEF;
    background: #F4F5F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../././../images/integrations_color.svg");
    margin-right: 10px;
}

.titleBoxTitle {
    color: #000;
    font-family: Geomanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FCFBFF;
    padding: 0% 2% 1% 2% !important;
}

.btnCenterAlign {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.body {
    height: 90%;
    width: 100%;
    background-color: white;
    overflow-y: auto;
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
}

.platformTitle {
    color: #000;
    font-family: Geomanist;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}

.mainDiv [class*='nav-tabs'] {
    display: flex;
    justify-content: center;
    align-self: center;
    border: none !important;
    border-bottom: 1px solid #ccc !important;
}

.mainDiv [class*='nav-link'] {
    font-size: 15px;
    font-weight: normal;
    font-family: Geomanist;
    cursor: pointer;
    color: #000000;
    border: none !important;
    /*width: 200px;*/
}

.mainDiv [class*='nav-link']:hover {
    color: #000000;
}

.mainDiv [class*='nav-link'][class*='active']:focus {
    border: 2px solid #000000 !important;
}

.mainDiv [class*='nav-link'][class*='active'] {
    font-weight: bold;
    border: none !important;
    cursor: auto;
    border-bottom: 3px solid #6750A4 !important;
    background-color: transparent !important;
}
