.table-fix-head thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.sticky-second-header {
  position: sticky;
  top: 4rem;
  z-index: 1;
  background-color: #fafafa;
  border-bottom: 2px solid #212529 !important;
}

#grade-book-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 5rem;
  width: 80%;
  max-height: 50rem;
  overflow: auto;
}

.student-response {
  width: 45%;
  height: 90%;
  margin: auto;
  border-radius: 8px;
}

.student-response-text {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  padding-right: 1.8rem;
}

.wrong {
  color: #d35f5e;
  border: 2px solid #d35f5e;
  background-color: #fff3eb;
}

.correct {
  color: #219025;
  border: 2px solid #219025;
  background-color: #d3ffdc;
}

.actual {
  color: #333333;
  border: 2px solid #333333;
  background-color: #cccccc;
}

.padding-zero {
  padding: 0px !important;
}

.header-text {
  --bs-text-opacity: 1;
  font-size: 1.2rem !important;
  color: #236ba6 !important;
  font-weight: 700 !important;
}

table {
  text-align: center !important;
}

thead > tr {
  height: 3rem;
}

tr > td {
  min-width: 15rem;
  height: 2.5rem;
  padding: 0.85rem !important;
}

tr > th {
  padding-bottom: 0.7rem !important;
  font-weight: 700;
  background-color: #FFFFFF !important;
}

.border-right {
  border-right: 1px solid #212529 !important;
}
