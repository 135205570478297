.titleFont {
    font-weight: 600;
    font-size: 2.03rem;
    line-height: 3.063rem;
    color: rgba(102, 102, 102, 1);
}

.bodyFont {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: rgba(134, 134, 134, 1);
}

.titleText {
    width: 90%;
    margin-top: 1.563rem;
}

.bodyText {
    width: 90%;
    margin-top: 0.625rem;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

