[class='pagination'] [class*='page-item'][class*='active'] [class*='page-link'] {
    z-index: 0 !important;
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 5px;
    /* margin-top: 5px; */
}

.paginationItem, .paginationItemActive, .paginationItemDisabled {
    display: flex;
    width: 32px !important;
    height: 32px;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    justify-content: center;
    align-items: center;

    font-size: 13px;
    font-family: 'Geomanist';
    font-weight: 600;
    line-height: normal;

    color: #000000;
    background-color: #FFFFFF;
}

.paginationItem:hover {
    background: #EBF6F9;
    cursor: pointer;
}

.paginationItemActive {
    border: none;
    color: #FFFFFF;
    background: #0B6F85;
}

.paginationItemDisabled {
    border: none;
    background-color: #F1F1F1;
}
