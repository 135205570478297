.select {
    /* min-width: min(300px, 20%); */
    z-index: 10;
}

.row {
    margin-bottom: 20px;
    display: block;
}

.left {
    display: inline-block;
    padding-right: 10px;
    text-align: right;
    width: 30%;
    color: #495057;
    font-size: 14px;
    font-family: 'Geomanist';
    position: relative;
    vertical-align: top;
    padding-top: 4.5px;
}

.right {
    display: inline-block;
    width: 70%;
    color: #000000;
    font-size: 18px;
    font-family: 'Geomanist';
}

.radioContainer {
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 15px 0 0 0 !important;
}

.radio {
    margin-right: 10px;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: 6px;
}

.correct {
    
}

.incorrect {
    margin-left: 25px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.buttonContainer {

}

.button {
    display: inline-block;
    font-family: 'Geomanist';
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.button:hover {
    cursor: pointer;
}

.close {
    margin-right: 10px;
    color: #495057;
    border: 0.125rem solid #495057;
}

.close:hover {
    background-color: #495057;
    color: #FFFFFF;
}

.save {
    color: #0277AD;
    border: 0.125rem solid #0277AD;
}

.save:hover {
    color: #FFFFFF;
    background-color: #0277AD;
}

.saveDisabled {
    color: #0277AD;
    border: 0.125rem solid #0277AD;
    opacity: 0.5;
}

.saveDisabled:hover {
    cursor: default !important;
}

.questionImgContainer {
    display: flex;
    justify-content: center;
}

.questionImg {
    max-width: min(48vw, 456px) !important;
    max-height: min(27vw, 256.5px) !important;
}

.saImg {
    max-width: 90% !important;
    max-height: min(27vw, 256.5px) !important;
}
