.container {
    display: flex;
    padding: 30px 5% 30px 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    min-height: 100%;
    background-color: #FCFBFF;
    overflow-y: auto;
}

.searchBox {
    font-family: Geomanist;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 6.208px;
    border: 0.841px solid #D0D0D0;
    background: #FFF;
    overflow-y: visible;
}

.searchBoxSearchBtn {
    margin: 0 10px;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.emptyTitle {
    color: #000;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.syncGradebookContainer {
    position: relative;
}

.syncGradebookBtn {
    width: 125px !important;
    margin: 0 10px;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
    display: flex;
    justify-content: center;
}

.syncGradebookBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.syncGradebookOptions {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px 0;
    width: 315px;
}

.syncGradebookOption {
    margin-left: 10px;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
    /* z-index: 9 !important; */
}

.syncGradebookOption:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.select {
    min-width: min(300px, 20%);
    z-index: 10;
}

.container [class*='nav-link'] {
    font-size: 1rem;
    color: #495057;
    border: none!important;
}

.container [class*='nav-link'][class*='active'] {
    font-size: 1rem;
    font-weight: 700;
    border-bottom: 3px solid rgba(0, 107, 155, 1)!important;
}

.container [class*='tab-content'] {
    width: 100%;
}

.tableContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.tableContainer table {
    font-family: 'Geomanist';
    table-layout: fixed;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center !important;
    vertical-align: middle !important;
}

.tableContainer tbody td{
    background-color: rgb(255 255 255);
}

.tableContainer thead th{
    vertical-align: middle;
}

/* .tableContainer tr > th {
    background-color: transparent !important;
} */

.tableContainer tr {
    border-top: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-right: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-left: none !important;
}

.surveyTableContainer tr td:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    box-shadow: inset 0.031rem 0 0 rgba(208, 208, 208, 1) !important;
}

.surveyTableContainer tr th:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    box-shadow: inset 0.031rem 0 0 rgba(208, 208, 208, 1) !important;
}

.gradeBookTableContainer tr td:nth-child(1){
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    box-shadow: inset 0.031rem 0 0 rgba(208, 208, 208, 1) !important;
}

.gradeBookTableContainer tr th:nth-child(1){
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    box-shadow: inset 0.031rem 0 0 rgba(208, 208, 208, 1) !important;
}

.gradeBookTableContainer tr td:nth-child(2){
    position: sticky;
    position: -webkit-sticky;
    left: 225px;
}

.gradeBookTableContainer tr th:nth-child(2){
    position: sticky;
    position: -webkit-sticky;
    left: 225px;
}

.gradeBookTableContainerWCheckbox tr td:nth-child(1){
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    box-shadow: inset 0.031rem 0 0 rgba(208, 208, 208, 1) !important;
}

.hoveredRow {
    background-color: #00000013 !important;
}

.gradeBookTableContainerWCheckbox tr th:nth-child(1){
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    box-shadow: inset 0.031rem 0 0 rgba(208, 208, 208, 1) !important;
}

.gradeBookTableContainerWCheckbox tr td:nth-child(2){
    position: sticky;
    position: -webkit-sticky;
    left: 100px;
}

.gradeBookTableContainerWCheckbox tr th:nth-child(2){
    position: sticky;
    position: -webkit-sticky;
    left: 100px;
}

.gradeBookTableContainerWCheckbox tr td:nth-child(3){
    position: sticky;
    position: -webkit-sticky;
    left: 325px;
}

.gradeBookTableContainerWCheckbox tr th:nth-child(3){
    position: sticky;
    position: -webkit-sticky;
    left: 325px;
}

.tableContainer [class*='pagination'] {
    width: 100%;
    justify-content: center;
}

.gradeBookSurvey {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
}

.gradeBookSurveyPointer {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
}

.gradeBookSurveyPointer:hover {
    cursor: pointer;
}

.gradeBookView, .gradeBookViewDisabled {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    text-decoration: underline;
}

.gradeBookView:hover {
    cursor: pointer;
    color: rgba(0, 107, 155, 1)!important;
}

.gradeBookCorrect {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px !important;
    background-color: #D3FFDC;
    color: #0A820E;
    border: 1px solid #0A820E;
}

.gradeBookIncorrect {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px !important;
    background-color: #FFF3EB;
    color: #C93E3E;
    border: 1px solid #C93E3E;
}

.gradeBookNotGraded {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #0277AD;
}

.gradeBookNotGraded:hover {
    cursor: pointer;
    background-color: #E5E5E5;
}

.gradeBookNotGradedDisabled {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px !important;
    background-color: #DADADA;
    color: #000000;
    border: 1px solid #000000;
}

.questionButton {
    display: flex !important;
    align-items: center;
    gap: 5px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.questionButton:hover {
    cursor: pointer;
    color: #0277AD;
}

.questionButton:focus {
    color: #0277AD;
}

.titleBox {
    border-radius: 6.68px 6.68px 0px 0px;
    border: 0.903px solid #EFEFEF;
    background: #F4F5F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/GradebookColor.svg");
    margin-right: 10px;
}

.titleBoxTitle {
    color: #000;
    font-family: Geomanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.customScoreBox {
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    border-radius: 3px !important;
    text-align: center;
}

.customScoreInput {
    width: 75px !important;
    height: 25px !important;
    font-family: "Geomanist";
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    /* margin-top: 15px !important; */
    background: transparent !important;
}

.customScoreInput:focus {
    border: none !important;
}

.pollTitleTitle {
    font-size: 20px;
    line-height: 22px;
    font-family: 'Geomanist';
    font-weight: 700;
    margin: 15px 5px 45px 0px;
}

.pollTitleText {
    font-size: 20px;
    line-height: 22px;
    font-family: 'Geomanist';
    font-weight: 400;
    margin: 15px 0px 45px 0px;
}

.surveyMCSSOption {
    height: 50px;
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
    margin: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    word-break: break-all;
}

/* .checkBox {
    z-index: 9;
} */

.expandCollapse {
    width: 15px;
    height: 15px;
    margin-right: 15px;
}

.subRow {
    /* background-color: #FF0000 !important; */
    font-size: 13px;
    line-height: 13px;
    text-align: end !important;
}

.mainRow {
    /* background-color: #00FF00 !important; */
    font-size: 13px;
    line-height: 13px;
}

.gradebookTableRow {
    cursor: default !important;
    /* pointer-events: none !important; */
}

/* .gradebookTableRowExpandable {
    
} */

.mainContainer {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FCFBFF;
    padding: 0% 2% 1% 2% !important;
}

.body {
    height: 90%;
    width: 100%;
    background-color: white;
    overflow-y: auto;
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
}

.mainDiv {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

@media only screen and (min-width: 1460px) {
    .syncGradebookOptions {
        width: 315px;
    }
}


.Filtercontainer {
    position: relative;
    font-family: Geomanist;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    background-color: #FFFFFF;
    cursor: default;
}

.FilterMenuContainer {
    position: absolute;
    min-width: 110px;
    max-width: 183px;
    top: calc(100% + 5px);
    z-index: 10000;
}


.menuList {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0 0 0 1px #B9B9B9;
    border-radius: 7px;
    box-shadow: 0px 0.5px 5px 0px #0000001F;
    overflow: auto;
    padding: 7px 7px 4px 0px; 
}

.FilterMenuContainer:after{
    content: '';
    border-top: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    background-color:white;
    position: absolute;
    left: 18px;
    top: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: 1000;
}

.menuItem {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 10px;
    background-color: #FFFFFF;
    z-index: 10000;

}

.menuItemText {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem:last-child {
    border-width: 0;
}

.menuItem:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}
