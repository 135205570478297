.row {
    margin: 1.0em;
}

.col {
    margin: 1.5em;
}

.containerClass {
    width: -webkit-fill-available;
    margin: 2.5em 2.5em;
    background-color: aliceblue;
}

.platformText {
    padding-top: 1px;
}

.cardHeader {
    margin-left: 0.5em;
    color: #000;
    font-family: Geomanist;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
}

.labelDesc {
    color: #000;
    font-family: Geomanist;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    width: 150px;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    height: 37px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.lmsHeader {
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-family: 'Poppins';
    color: #00578C;
}

.smallBox {
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
    width: 400px;
    margin-top: 20px;
    height: fit-content;
}

.mediumBox {
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
    width: 700px;
    margin-top: 20px;
    height: fit-content;
}

@media only screen and (min-width: 1580px) {
    .labelDesc {
        width: 175px;
    }
}

@media only screen and (max-width: 1400px) {
    .labelDesc {
        font-size: 11px;
    }
}

@media only screen and (min-width: 1850px) {
    .smallBox {
        width: 500px;
    }
    .mediumBox {
        width: 750px;
    }
}

@media only screen and (max-width: 1849px) {
    .smallBox {
        width: 450px;
    }
    .mediumBox {
        width: 650px;
    }
}

@media only screen and (max-width: 1700px) {
    .smallBox {
        width: 420px;
    }
    .mediumBox {
        width: 600px;
    }
}

@media only screen and (max-width: 1550px) {
    .smallBox {
        width: 350px;
    }
    .mediumBox {
        width: 550px;
    }
}

@media only screen and (max-width: 1350px) {
    .smallBox {
        width: 315px;
    }
    .mediumBox {
        width: 475px;
    }
}

@media only screen and (max-width: 1250px) {
    .smallBox {
        width: 275px;
    }
    .mediumBox {
        width: 425px;
    }
}

@media only screen and (max-width: 1010px) {
    .smallBox {
        width: 200px;
    }
    .mediumBox {
        width: 350px;
    }
}
