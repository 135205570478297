.container {
    position: relative;
    font-family: Geomanist;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    cursor: default;
}

.menuContainer {
    position: absolute;
    min-width: 110px;
    max-width: 150px;
    top: calc(100% + 5px);
    right: -15px;
    z-index: 10000;
}

.createMenuContainer {
    position: absolute;
    min-width: 110px;
    max-width: 183px;
    top: calc(100% + 5px);
    z-index: 10000;
}


.menuList {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0 0 0 1px #B9B9B9;
    border-radius: 7px;
    box-shadow: 0px 0.5px 5px 0px #0000001F;
    overflow: auto;
}

.menuContainer:after{
    content: '';
    border-top: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    background-color:white;
    position: absolute;
    right: 18px;
    top: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 0.5px 5px 0 #0000001F;
    z-index: 1000;
}

.menuItem {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 10px;
    background-color: #FFFFFF;
    z-index: 10000;

}

.menuItemText {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem:last-child {
    border-width: 0;
}

.menuItem:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.backDrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
}

