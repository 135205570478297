@import "@fontsource/roboto";

.container {
  height: 100%;
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E6F2F7;
}

.titleContainer {
  margin: 3% 0;
  display: flex;
  align-items: center;
}

.title {
  width: 100%;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #000000;
  text-align: center;
}

.resultBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 3%;
  box-shadow: 5px 9px 11px 0px #00000040;

}

.resultTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #000000;
  margin: 20px 0;
}

.description {
  font-family: 'Geomanist';
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  text-align: center;
}

.pollDetailContainer {
  padding: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pollTitlePanelContainer {
  height: 12%;
  min-height: 90px;
  width: 100%;
  display: flex;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 1.66994px 1.66994px 22.5852px 0.834969px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.pollPanelSubtitle {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #000000;
}

.pollPanelTitle {
  margin-top: 10px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.035em;
  color: #000000;
  word-break: break-word;
  margin-right: 7px;
}

.QRCodePanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 40px 20px;
  overflow-x: auto;
  margin-right: 20px;
}

.QRCodeText {
  margin-top: 10px;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.035em;
  color: #000000;
  word-break: break-word;
}

.questionPanel {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 40px 35px;
  overflow-x: auto;
}

.questionPanelTitle {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

}

.questionPanelCurSerialNo {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.035em;
  background: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.questionPanelTotalCount {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.035em;
  color: #939393;
  margin-left: 6px;
  white-space: nowrap;
}

.gradientWrapQuesType {
  border-radius: 47px;
  background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  margin-left: 20px;
  position: relative;
  padding: 1px;
}

.questionPanelQuestionTypeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #000000;
  height: 36px;
  border-radius: 47px;
  background-color: #f2eef6;
  padding: 0 1.5vw;

  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #000000;
}



.questionPanelQueTitle {
  width: 100%;
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  /* margin-bottom: 30px; */
  word-break: break-word;
  word-wrap: break-word;
}


.optionsContainer {
  width: 100%;
  margin-top: 20px;
  font-family: 'Geomanist';
  display: flex;
  flex-direction: column;
}


.optionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.option {
  display: flex;
  align-items: center;
  flex: auto;
  min-height: 55px;
  position: relative;
  height: 100%;
  padding: 5px 20px;
  overflow: hidden;
}

.optionIndex {
  position: relative;
  z-index: 1;
  font-weight: 700;
}

.optionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 20px;
  position: relative;
  z-index: 1;
}

.optionCountBar {
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #CACACA;
  border-radius: 10px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.optionCountBar.correct{
  background-color: #96C8DF;
}

.countValueContainer {
  z-index: 1;
  margin-left: 20px;
  font-weight: 700;
}

.optionTextToolTip {
  background-color: #6C6C6C;
  color: #FFFFFF;
  font-family: 'Geomanist';
  padding: 10px;
  opacity: 0.8;
  z-index: 1;
  border-radius: 5px;
}

.optionTextToolTip::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #6C6C6C;
  bottom: -7px;
}

.descriptionText {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.035em;
  color: #000000;
}