.container {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.unchecked {
    background-color: transparent;
    border: 1px solid #858687;
}


.checked {
    background-color: #126FC7;
    border: 1px solid #126FC7;
}

.disabled {
    opacity: .5;
    background-color: #E6E6E6;
    cursor: not-allowed;
}

.label {
    flex: 1;
    margin-left: 8px;
    font-family: Geomanist;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.disabledLabel {
    opacity: .5;
}